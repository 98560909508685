import React, { useEffect } from 'react'
import { FiBookOpen, FiCode, FiCodepen, FiDollarSign, FiDownload, FiEye, FiMail, FiPlus, FiSend, FiShoppingCart, FiUpload, FiUsers } from 'react-icons/fi'
import { Stat } from '../../Components/Stats/StatsWithIconComponent'
import { Box, Button, Container, HStack, Select, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { FaBarcode, FaBars, FaBuysellads, FaChartBar, FaMoneyBill, FaMoneyBillWaveAlt, FaSalesforce } from 'react-icons/fa'
import { TableComponent } from '../../Components/Tables/TableComponent'
import { useGlobalState } from '../../Hooks/Contexts'
import CreateProjects from '../Projects/CreateProjects'
import { useNavigate } from 'react-router-dom'

function DashboardPage() {
	const { user } = useGlobalState();
	const navigate = useNavigate();
	const stats = [
		{
			icon: FiUsers,
			label: 'Total Subscribers',
			value: '71,887',
			delta: {
				value: '320',
				isUpwardsTrend: true,
			},
		},
		{
			icon: FiEye,
			label: 'Total Viewers',
			value: '56.87%',
			delta: {
				value: '2.3%',
				isUpwardsTrend: true,
			},
		},
		{
			icon: FiBookOpen,
			label: 'Total Course',
			value: '12.87%',
			delta: {
				value: '0.1%',
				isUpwardsTrend: false,
			},
		},
		{
			icon: FaMoneyBillWaveAlt,
			label: 'Sales',
			value: '12.87%',
			delta: {
				value: '0.1%',
				isUpwardsTrend: false,
			},
		},
	]

	// if (user?.projects?.length === 0) return <CreateProjects />

	if (user.projects?.length === 0) {
		navigate('/projects/create-project');
		console.log("projects length: ", user.projects.length)
	}
	
	
	
	return (
		<Box
			p={5}
			bgColor={'#f5faff'}
		>
			<HStack
				px={{
					base: '4',
					md: '6',
				}}
			>
				<Box

					w={'100%'}
					display={{ lg: 'flex' }}
					flexDirection={{ base: 'column', lg: 'row' }}

				>
					<Text
						fontWeight="medium"
						mr={5} >
						Orders
					</Text>
					<Select
						placeholder='All Courses'
						fontSize={'12px'}
						w={{ base: '60%', lg: '15%' }}
						bgColor={'white'}
						px={{
							base: '1',
							md: '1'
						}}>
						<option value='option1'>Option 1</option>
						<option value='option2'>Option 2</option>
						<option value='option3'>Option 3</option>
					</Select>
					<Select
						placeholder='All Enrolled Courses'
						fontSize={'12px'}
						w={{ base: '80%', lg: '20%' }}
						bgColor={'white'}
						px={{
							base: '1',
							md: '1'
						}}>
						<option value='option1'>Option 1</option>
						<option value='option2'>Option 2</option>
						<option value='option3'>Option 3</option>
					</Select>
				</Box>
			</HStack>
			<Box
				as="section"
				py={{
					base: '4',
					md: '8',
				}}
			>
				<Container maxW='full'>
					<SimpleGrid
						columns={{
							base: 1,
							md: 4,
						}}
						gap={{
							base: '5',
							md: '6',
						}}
					>
						{stats.map((x, i) => (
							<>
								<Stat key={i}
									icon={x.icon}
									label={x.label}
									value={x.value}
									delta={x.delta}
								/>
							</>
						))}
					</SimpleGrid>
				</Container>
			</Box>
			<HStack
				px={{
					base: '4',
					md: '5',
				}}
			>
				<HStack
					w={{ lg: '100%' }}
					overflowX={'auto'}
				>

					<Button
						bgColor={'white'}
						gap={2}
						fontSize={'12px'}>
						<FiPlus />
						Input Order
					</Button>
					<Button
						bgColor={'white'}
						gap={2}
						fontSize={'12px'}>
						<FiDownload />
						Export
					</Button>
					<Button
						bgColor={'white'}
						gap={2}
						fontSize={'12px'}>

						<FaBarcode />
						Barcode
					</Button>
					<Button
						bgColor={'white'}
						gap={2}
						fontSize={'12px'}>

						<FiUpload />
						Upload Receipt Number
					</Button>
					<HStack
						w={'50%'}
						justifyContent={'right'}>
						<Button
							bgColor={'white'}
							gap={2}
							fontSize={'12px'}>

							<FaChartBar />
							View More Stats
						</Button>
					</HStack>
				</HStack>
			</HStack>
			<TableComponent />
		</Box>
	)
}

export default DashboardPage