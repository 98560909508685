import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Progress, Stack } from '@chakra-ui/react'
import React, { useRef } from 'react';
import { motion } from "framer-motion";

const ProjectName = ({ page, setPage, data, setData, x, setX}) => {
	let inputRef = useRef();

	const handlePageChange = (type) => {
		console.log(inputRef.current)
		setData({
			...data,
			projectName: inputRef.current
		})
		if (type === 'previous') {
			setPage(page - 1);
			setX(-1000);
		} else if (type === 'next' ) {
			if (data.projectName === null || data.projectName?.length === 0) {
				alert('Please input project name!');
			} else {
				setPage(page + 1);
				setX(1000);
			};
		};
	};


	return (
		<motion.div                            //updated the div tag
			initial={{ x: x }}
			transition={{ duration: 0.5 }}
			animate={{ x: 0 }}
		>
			<Stack h='100vh' display='flex' flexDir='column' justifyContent='center' alignItems='center'>
				<Box bg='white' minW={{ base: 'full', lg: '70%' }} position='relative' p={20} display='flex' flexDir='column' justifyContent='center' alignItems='center' gap={5}>
					<Progress value={(page / 4) * 100} position='absolute' top={0} right={0} left={0} />
					<Heading size='md' colorScheme='blackAlpha.800'>Let's start with a name for your project </Heading>

					<FormControl>
						<FormLabel>Project Name: </FormLabel>
						<Input
							onChange={e => {
								inputRef.current = e.target.value
							}}
							placeholder='Example project'
							value={data.projectName}
						/>
					</FormControl>
					<Flex gap={5} position='absolute' bottom={0} right={0} m={5}>
						<Button onClick={() => handlePageChange('previous')} colorScheme='red'>Previous</Button>
						<Button onClick={() => handlePageChange('next')} colorScheme='blue'>Next</Button>
					</Flex>
				</Box>
			</Stack>

		</motion.div>
	)
}

export default ProjectName