import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthenticationRouter from "./AuthenticationRouter";
import PromptListPage from "../Pages/Prompts/PromptListPage";
import PromptFileListPage from "../Pages/Prompts/PromptFileListPage";
import PrompResultFilePage from "../Pages/Prompts/PromptResulFilePage";


function AuthRouter() {
	const authRouter = [...AuthenticationRouter];
	
	return (
		<Routes>
			<Route path="*" element={<Navigate to="/login" />} /> 
			{authRouter.map((item, index) => {
				return <Route key={index} path={item.path} element={item.element} />;
			})}
			<Route path="home-prompt-gpt" element={<PromptListPage />} />
			<Route path="home-prompt-gpt/:id" element={<PromptFileListPage />} />
			<Route path="home-prompt-gpt/:id/:id_file" element={<PrompResultFilePage />} />
		</Routes>
	);
}

export default AuthRouter;
