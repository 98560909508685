import { Box, Button, FormControl, FormLabel, HStack, Input, Select, Stack, Text } from "@chakra-ui/react"
import { PricingCornerPage } from "../../Components/Pricing/PricngCornerBadge"
import { FiPlusCircle } from "react-icons/fi"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import OfferStats from "./OfferStats"
import { TableComponent } from "../../Components/Tables/TableComponent"
import { OffersTableAll } from "./OffersTable"
import { ActiveOfferTable, DraftOfferTable, OfferTableComponent } from "./OffersTableComponent"
import { useState } from "react"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'

function OffersPage() {
    const [title,setTitle] = useState('')
    const [type,setType] = useState('')
    const [price, setPrice] = useState('')
    const [schedule, setSchedule] = useState('')
    const [status, setStatus] = useState('')
    const [img, setImg] = useState('')
    const handleAdd = (props) => {
        const newOffer = [
            {
                title: title,
                type: type,
                price: price,
                schedule: schedule,
                status: status,
                img: img
            }
        ]
    }
    const [inputModal, setInputModal] = useState(false)
    return (
        <>
        <HStack
        px={{
            base:4,
            lg: 6
        }}>
            <HStack
            w={'100%'}
            >
                <Text fontWeight={'medium'} fontSize={'20px'}>
                    Offers
                </Text>
                <Stack
                w={'100%'}
                align={'end'}
                >
                    <Button
                    bgColor={'orange'}   
                    onClick={() => setInputModal(true)}
                    >
                        <FiPlusCircle /> Add New Offer
                    </Button>
                </Stack>
            </HStack>
        </HStack>
        <Box
        mt={{
            base: 4,
            lg: 5
        }}
        px={{
            base:0,
            lg: 2
        }}
        >
            <OfferStats/>
        </Box>
        <Box
        mt={{
            base: 4,
            lg: 5
        }}
        px={{
            base:0,
            lg: 6
        }}
        >
            <Tabs>
                <TabList>
                    <Tab>All</Tab>
                    <Tab>Published</Tab>
                    <Tab>Draft</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                    <OfferTableComponent/>
                    </TabPanel>
                    <TabPanel>
                    <ActiveOfferTable/>
                    </TabPanel>
                    <TabPanel>
                    <DraftOfferTable/>                        
                    </TabPanel>
                </TabPanels>
                </Tabs>
        </Box>

        <Modal isOpen={inputModal} onClose={() => setInputModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Offer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
                <FormLabel>Offer Title</FormLabel>
                <Input placeholder='Input Title' onChange={(e) => setTitle(e.target.value)}/>
            </FormControl>
            <FormControl>
                <FormLabel>Select Type</FormLabel>
                <Select placeholder="Select Schedule" onChange={(e) => setType(e.target.value)}>
                    <option>Subscription</option>
                </Select>
            </FormControl>
            <FormControl>
                <FormLabel>Offer Price</FormLabel>
                <Input type="number" placeholder='Input Price' onChange={(e) => setPrice(e.target.value)} />
            </FormControl>
            <FormControl>
                <FormLabel>Select Schedule</FormLabel>
                <Select placeholder="Select Schedule" onChange={(e) => setSchedule(e.target.value)}>
                    <option>Week</option>
                    <option>Month</option>
                    <option>Year</option>
                </Select>
            </FormControl>
            <FormControl>
                <FormLabel>Select Status</FormLabel>
                <Select placeholder="Select Schedule" onChange={(e) => setStatus(e.target.value)}>
                    <option>Published</option>
                    <option>Draft</option>
                </Select>
            </FormControl>
            <FormControl>
                <FormLabel>Thumbnail</FormLabel>
                <Input type='file' placeholder='Input Price' onChange={(e) => setImg(e.target.value)} />
            </FormControl>

          </ModalBody>
            
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => handleAdd ()}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
        </>
    )
}
export default OffersPage