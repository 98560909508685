import React, { useEffect, useState } from 'react';
import { getCollectionFirebase, getCollectionFirebaseV2 } from '../../Apis/firebaseApi';
import { useGlobalState } from '../../Hooks/Contexts';
import { ShippingTable } from '../../Components/Tables/ShippingTable';
import { Button, Center, Flex, Heading } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";



const ShippingDashboard = () => {
	const { currentProject } = useGlobalState();
	const [shippingData, setShippingData] = useState([]);
	const [nextDataAvailable, setNextDataAvailable] = useState(true);
	const [selectedRows, setSelectedRows] = useState([]);
	const navigate = useNavigate();



	const getOrderWithShipping = async () => {
		const conditions = [
			{
				field: "projectsId",
				operator: "==",
				value: currentProject.id,
			},
			{
				field: "withShipping",
				operator: "==",
				value: true,
			},
		];
		const sortBy = { field: "lastUpdated", direction: "desc" }
		try {
			const result = await getCollectionFirebase("orders", { conditions }, { sortBy }, { limitValue: 5 });
			setShippingData(result);
		} catch (error) {
			console.log("error getting orders with shipping", error.message)
		}
	};

	const fetchNext = async () => {
		const lastVisible = shippingData[shippingData?.length - 1]?.lastUpdated;
		const conditions = [
			{
				field: "projectsId",
				operator: "==",
				value: currentProject.id,
			},
			{
				field: "withShipping",
				operator: "==",
				value: true,
			},
		];
		const sortBy = { field: 'lastUpdated', direction: 'desc' };
		const limitValue = 5;
		// const afterData = shippingData[shippingData?.length - 1]?.lastUpdated
		try {
			const next = await getCollectionFirebaseV2('orders', { conditions }, { sortBy }, { limitValue }, { afterData: lastVisible })
			if (next?.length === 0) {
				setNextDataAvailable(false);
			} else {
				setShippingData([...shippingData, ...next]);
			}
		} catch (error) {
			console.log("error get nextdata : ", error.message);
		};
	};


	const handlePrint = () => {
		if (selectedRows?.length === 0) {
			window.alert("Choose at least one resi!")
		} else {
			navigate('/print-resi',
				{ state: { selectedRows, data: shippingData } }
			);
		};
	};


	useEffect(() => {
		getOrderWithShipping();
		return () => {
			setShippingData([]);
		};
	}, []);

	useEffect(() => {
		getOrderWithShipping();
		return () => {
			setShippingData([]);
		};
	}, [currentProject]);




	return (
		<>
			<Heading m={2} size="md">Shipping Data</Heading>
			<Flex my={5} flexDir="row" justifyContent="flex-end" alignItems="center">
				<Button
					onClick={handlePrint}
					colorScheme='blue'
				>
					Print Resi
				</Button>
			</Flex>

			<ShippingTable
				data={shippingData}
				selectedRows={selectedRows}
				setSelectedRows={setSelectedRows}
			/>
			{nextDataAvailable &&
				<Center mt={2}>
					<Button size='sm' onClick={fetchNext} variant="outline">Next</Button>
				</Center>
			}
		</>
	)
}

export default ShippingDashboard