import { Container, Heading, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { FiUser } from 'react-icons/fi'
import BreadCrumbComponent from '../../Components/BreadCrumbs/BreadCrumbComponent'
import IconCardComponent from '../../Components/Cards/IconCardComponent'

function SettingsPage() {
	const data = [
		{ icon: FiUser, title: 'Account', link: 'account', description: 'Update your acccount' },
		{ icon: FiUser, title: 'Domains', link: '/redirect-to-admin', description: 'Update your acccount' },
	];



	return (
		<Container>
			<Heading>Setting</Heading>
			<BreadCrumbComponent data={[{ title: 'Settings', link: '/settings' }]} />
			<SimpleGrid columns='3'>
				{data.map((x, i) => <IconCardComponent key={i} data={x} />)}
			</SimpleGrid>
			{/* <ExportExcel excelData={addressToPrint} fileName='alamat2' /> */}
			{/* <Button onClick={printAddress}>Print Alamat Pembeli</Button>
			<Button onClick={getPayments}>Fetch Payments</Button>
			<Button onClick={()=>console.log(paymentsPrint)}>clg Payments</Button>
			<Button onClick={()=>navigate('/shipping')}>go to html pdf</Button> */}
		</Container>
	)
}

export default SettingsPage