import {
	Box,
	Button,
	ButtonGroup,
	Container,
	HStack,
	Icon,
	Input,
	InputGroup,
	InputLeftElement,
	Stack,
	Text,
	useBreakpointValue,
} from '@chakra-ui/react'
import { FiSearch } from 'react-icons/fi'
import { OffersTableAll } from './OffersTable'
import { useState } from 'react'
import logo from '../../Assets/Logo/64174d501639e03e287e2160.png'

const Offer = [ 
	{
		id: '1',
		title: 'Offer 1',
		type: 'subscription',
		price: '300000',
		schedule: 'Week',
		status: 'published',
		img: logo
	},
	{
		id: '2',
		title: 'Offer 2',
		type: 'subscription',
		price: '300000',
		schedule: 'Week',
		status: 'draft',
		img: logo
	},
	{
		id: '3',
		title: 'Offer 3',
		type: 'subscription',
		price: '300000',
		schedule: 'Month',
		status: 'published',
		img: logo
	},
	{
		id: '4',
		title: 'Offer 4',
		type: 'subscription',
		price: '300000',
		schedule: 'Week',
		status: 'published',
		img: logo
	},
	{
		id: '5',
		title: 'Offer 5',
		type: 'subscription',
		price: '300000',
		schedule: 'Week',
		status: 'draft',
		img: logo
	},
]


export const OfferTableComponent = () => {
	const [allData, setAllData] = useState(Offer)
	const [search, setSearch] = useState([])
	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	})
	
	const handleSearch = () =>{
		const data = Offer

		if (search === '') {
			setAllData(data)
		} else {
			const find = Offer.find((x) => (x.title).toLowerCase().includes(search.toLowerCase()))
			setAllData([find])
		}
		
	}
	return (
		<Container
			py={{
				base: '2',
				md: '2',
			}}
			maxW='7xl'
		>
			<Box
				bg="bg-surface"
				boxShadow={{
					base: 'none',
					md: 'sm',
				}}
				borderRadius={{
					base: 'none',
					md: 'lg',
				}}
			>
				<Stack spacing="5">
					<Box
						px={{
							base: '4',
							md: '0',
						}}
						pt="2"
					>
						<Stack
							direction={{
								base: 'column',
								md: 'row',
							}}
							justify="space-between"
						>
							<InputGroup maxW="xs">
								<InputLeftElement pointerEvents="none">
									<Icon as={FiSearch} color="muted" boxSize="5" />
								</InputLeftElement>
								<Input placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
								<Button onClick={() => handleSearch ()}>Search</Button>
							</InputGroup>
						</Stack>
					</Box>
					<Box overflowX="auto">
						<OffersTableAll data={allData} />
					</Box>
					<Box
						px={{
							base: '4',
							md: '6',
						}}
						pb="5"
					>
						<HStack spacing="3" justify="space-between">
							{!isMobile && (
								<Text color="muted" fontSize="sm">
									Showing 1 to 5 of 42 results
								</Text>
							)}
							<ButtonGroup
								spacing="3"
								justifyContent="space-between"
								width={{
									base: 'full',
									md: 'auto',
								}}
								variant="secondary"
							>
								<Button>Previous</Button>
								<Button>Next</Button>
							</ButtonGroup>
						</HStack>
					</Box>
				</Stack>
			</Box>
		</Container >
	)
}

export const ActiveOfferTable = () => {
	const find = Offer.filter((x) => (x.status) === 'published')
	const [filtered, setFiltered] = useState(find)
	const [searched, setSearched] = useState('')
	const handleSearch = () => {
		if (searched === '') {
			setFiltered(find)
		}else {
			const search = find.find((x) => (x.title).toLowerCase().includes(searched.toLowerCase()))
			setFiltered([search])
		}
	}
	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	})
	return (
		<Container
			py={{
				base: '2',
				md: '2',
			}}
			maxW='7xl'
		>
			<Box
				bg="bg-surface"
				boxShadow={{
					base: 'none',
					md: 'sm',
				}}
				borderRadius={{
					base: 'none',
					md: 'lg',
				}}
			>
				<Stack spacing="5">
					<Box
						px={{
							base: '4',
							md: '0',
						}}
						pt="2"
					>
						<Stack
							direction={{
								base: 'column',
								md: 'row',
							}}
							justify="space-between"
						>
							<InputGroup maxW="xs">
								<InputLeftElement pointerEvents="none">
									<Icon as={FiSearch} color="muted" boxSize="5" />
								</InputLeftElement>
								<Input placeholder="Search" onChange={(e) => setSearched(e.target.value)} />
								<Button onClick={() => handleSearch ()}>Search</Button>
							</InputGroup>
						</Stack>
					</Box>
					<Box overflowX="auto">
						<OffersTableAll data={filtered}/>
					</Box>
					<Box
						px={{
							base: '4',
							md: '6',
						}}
						pb="5"
					>
						<HStack spacing="3" justify="space-between">
							{!isMobile && (
								<Text color="muted" fontSize="sm">
									Showing 1 to 5 of 42 results
								</Text>
							)}
							<ButtonGroup
								spacing="3"
								justifyContent="space-between"
								width={{
									base: 'full',
									md: 'auto',
								}}
								variant="secondary"
							>
								<Button>Previous</Button>
								<Button>Next</Button>
							</ButtonGroup>
						</HStack>
					</Box>
				</Stack>
			</Box>
		</Container >
	)
}

export const DraftOfferTable = () => {
	const find = Offer.filter((x) => (x.status) === 'draft')
	const [filtered, setFiltered] = useState(find)
	const [searched, setSearched] = useState('')
	const handleSearch = () => {
		if (searched === '') {
			setFiltered(find)
		}else {
			const search = find.find((x) => (x.title).toLowerCase().includes(searched.toLowerCase()))
			setFiltered([search])
		}
	}
	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	})
	return (
		<Container
			py={{
				base: '2',
				md: '2',
			}}
			maxW='7xl'
		>
			<Box
				bg="bg-surface"
				boxShadow={{
					base: 'none',
					md: 'sm',
				}}
				borderRadius={{
					base: 'none',
					md: 'lg',
				}}
			>
				<Stack spacing="5">
					<Box
						px={{
							base: '4',
							md: '0',
						}}
						pt="2"
					>
						<Stack
							direction={{
								base: 'column',
								md: 'row',
							}}
							justify="space-between"
						>
							<InputGroup maxW="xs">
								<InputLeftElement pointerEvents="none">
									<Icon as={FiSearch} color="muted" boxSize="5" />
								</InputLeftElement>
								<Input placeholder="Search" onChange={(e) => setSearched(e.target.value)} />
								<Button onClick={() => handleSearch ()}>Search</Button>
							</InputGroup>
						</Stack>
					</Box>
					<Box overflowX="auto">
						<OffersTableAll data={filtered}/>
					</Box>
					<Box
						px={{
							base: '4',
							md: '6',
						}}
						pb="5"
					>
						<HStack spacing="3" justify="space-between">
							{!isMobile && (
								<Text color="muted" fontSize="sm">
									Showing 1 to 5 of 42 results
								</Text>
							)}
							<ButtonGroup
								spacing="3"
								justifyContent="space-between"
								width={{
									base: 'full',
									md: 'auto',
								}}
								variant="secondary"
							>
								<Button>Previous</Button>
								<Button>Next</Button>
							</ButtonGroup>
						</HStack>
					</Box>
				</Stack>
			</Box>
		</Container >
	)
}