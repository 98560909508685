//format number

// format ini untuk semua price rates, tinggal ditambahkan currency nya Ex : Rp. $. DLL
export function formatFrice(value) {
  const number = value ? parseInt(value) : 0;

  if (number < 1000) {
    let val = number?.toFixed(2);
    if (val === '0.00') {
      val = '0';
    } else {
      val = parseFloat(val).toString();
    }
    return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return number?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

}






//format IDR
//format USD
