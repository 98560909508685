import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardRouter from "./DashboardRouter";
import SettingRouter from "./SettingRouter";
import PagesRouter from "./PagesRouter";

function MainRouter() {
	const allRouter = [...DashboardRouter, ...PagesRouter];

	return (
		<Routes>
			<Route path="settings/*" element={<SettingRouter />} />
			{allRouter.map((item, index) => {
				return (
					<Route
						key={index}
						path={item.path}
						element={item.element}
					/>
				);
			})}

		</Routes>
	);
}

export default MainRouter;
