import { Heading, Stack } from '@chakra-ui/react'
import React, { useState } from 'react'
import Features from './Multi-form/Features'
import ProjectName from './Multi-form/ProjectName'
import Domains from './Multi-form/Domains'
import Welcome from './Multi-form/Welcome'
import { motion } from "framer-motion";


const CreateProjects = () => {
    const [page, setPage] = useState(0);
    const [data, setData] = useState({});
    const [x, setX] = useState(0);

    const ChildComponent = () => {
        // if (page === 0) return <Welcome page={page} setPage={setPage}/>
        switch (page) {
            case 0:
                return <Welcome page={page} setPage={setPage} data={data} setData={setData} x={x} setX={setX} />
            case 1:
                return <ProjectName page={page} setPage={setPage} data={data} setData={setData} x={x} setX={setX} />
            case 2:
                return <Features page={page} setPage={setPage} data={data} setData={setData} x={x} setX={setX} />
            case 3:
                return <Domains page={page} setPage={setPage} data={data} setData={setData} x={x} setX={setX} />
            default:
                return <Welcome page={page} setPage={setPage} data={data} setData={setData} x={x} setX={setX} />
        };
    };

    return (
        <>
            <motion.div                            //updated the div tag
                initial={{ x: x }}
                transition={{ duration: 0.5 }}
                animate={{ x: 0 }}
            >
                <ChildComponent />
            </motion.div>
        </>
    )
}

export default CreateProjects