import { Stack } from "@chakra-ui/react";
import Layout from "./Layouts";
import AuthRouter from "./Router/AuthRouter";
import MainRouter from "./Router/MainRouter";
import { useGlobalState } from "./Hooks/Contexts";
import { useEffect, useState } from "react";

function App() {
	const globalState = useGlobalState();
	const storedUser = localStorage.getItem('user');
	const [authenticated, setAuthenticated] = useState(false);


	useEffect(()=>{
		if (globalState?.user) setAuthenticated(true)
		else setAuthenticated(false)
	},[globalState?.user])

	return (
		<Stack>
			 {globalState.isLoggedin ? 
				<Layout>
					<MainRouter />
				</Layout>
				:
				<AuthRouter />
			}
		</Stack>
	);

}

export default App;
