import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Box, Button, Center, Container, Flex, HStack, Heading, Input, Radio, RadioGroup, Select, Spacer, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { useState } from "react";
import { FiEyeOff, FiVideo, FiVolume2 } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";

function EditOffers () {
const navigate = useNavigate();
// const [data, setData] = useState('')
// const MediaType = () => (
//     <Tabs isFitted variant="soft-rounded">
//         <TabList>
//             <Tab>
//                 <HStack>
//                     <FiEyeOff />
//                     <Text>None</Text>
//                 </HStack>
//             </Tab>
//             <Tab>
//                 <HStack>
//                     <FiVideo />
//                     <Text>Video</Text>
//                 </HStack>
//             </Tab>
//             <Tab>
//                 <HStack>
//                     <FiVolume2 />
//                     <Text>Audio</Text>
//                 </HStack>
//             </Tab>
//         </TabList>

//         <TabPanels>
//             <TabPanel></TabPanel>
//             <TabPanel>
//                 <Container
//                     borderRadius="md"
//                     p="5"
//                     border="1px"
//                     borderColor="gray"
//                     borderStyle="dotted"
//                 >
//                     <Center>
//                         <FiVideo />
//                     </Center>
//                     <Center>
//                         <Button>Upload Video</Button>
//                     </Center>
//                 </Container>
//             </TabPanel>
//             <TabPanel>
//                 <Container
//                     borderRadius="md"
//                     p="5"
//                     border="1px"
//                     borderColor="gray"
//                     borderStyle="dotted"
//                 >
//                     <Center>
//                         <FiVolume2 width="25px" />
//                     </Center>
//                     <Center>
//                         <Button>Upload Audio</Button>
//                     </Center>
//                 </Container>
//             </TabPanel>
//         </TabPanels>
//     </Tabs>
// );

return (
    <>
        <Box>
            <HStack>
                <HStack
                    color="#2c698d"
                    fontSize="14px"
                    onClick={() => navigate(-1)}
                >
                    <ChevronLeftIcon />
                    <Text>Back</Text>
                </HStack>
                {/* <Heading>{data?.title ? data.title : <></>}</Heading> */}
                <Spacer />
                <Button>Check Console</Button>
                <Button colorScheme="red">Delete</Button>
                <Button colorScheme="green">Save</Button>
            </HStack>
            <Flex direction={{base: 'column', lg:'row'}}>
                <Box width={{base: '100%', lg:"70%"}}>
                    <Box borderRadius="md" shadow="base" p="2" m="1">
                        <Text m="1">Offer Title :</Text>
                        <Input type="text" />

                        <Text m="1">Offer Type :</Text>
                        <Input type="text" />

                        <Text m="1">Offer Price :</Text>
                        <Input type="text" />

                        <Text m="1">Offer Schedule :</Text>
                        <Select>
                            <option>Week</option>
                            <option>Month</option>
                            <option>Year</option>
                        </Select>

                        {/* <Text m="1">Media :</Text> */}
                        <Box p="5">
                            {/* {data?.media ? (
                                <ReactPlayer
                                    width="full"
                                    controls={true}
                                    url={data.media}
                                />
                            ) : (
                                <MediaType />
                            )} */}
                        </Box>

                        {/* <Box
                            borderRadius="md"
                            border="1px"
                            borderColor="gray"
                            p="2"
                            mr="2"
                            ml="2"
                        >
                            <ReactQuill theme="snow" />
                        </Box> */}

                        {/* <HStack m="2">
                            <Text>Files</Text>
                            <Spacer />
                            <Button size="xs" colorScheme="green">
                                Add Files
                            </Button>
                        </HStack> */}
                        <Box
                            borderRadius="md"
                            border="1px"
                            borderColor="gray.50"
                            pl="2"
                            pr="2"
                            mr="2"
                            ml="2"
                        >
                            {/* {data?.download ? (
                                <Box
                                    borderBottom="1px"
                                    borderColor="gray.50"
                                >
                                    <HStack>
                                        <Text>
                                            {getFileName(
                                                data.download
                                            )}
                                        </Text>
                                        <Spacer />
                                        <a
                                            href={data.download}
                                            download
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FiDownload />
                                        </a>
                                        <FiDelete />
                                    </HStack>
                                </Box>
                            ) : (
                                <Box
                                    borderBottom="1px"
                                    borderColor="gray.50"
                                ></Box>
                            )} */}
                        </Box>
                    </Box>
                </Box>

                <Box width={{base: '50%', lg: '30%'}}>
                    <Box borderRadius="md" shadow="base" p="2" m="1">
                        <Heading fontSize="md">Status</Heading>
                        <RadioGroup>
                            <Stack direction="column">
                                <RadioGroup>
                                    <Stack>
                                        <Radio value="draft">
                                            Draft
                                        </Radio>
                                        <Radio value="published">
                                            Published
                                        </Radio>
                                    </Stack>
                                </RadioGroup>
                            </Stack>
                        </RadioGroup>
                    </Box>

                    {/* <Box borderRadius="md" shadow="base" p="2" m="1">
                        <Heading fontSize="md">Thumbnail</Heading>
                    </Box>

                    <Box borderRadius="md" shadow="base" p="2" m="1">
                        <Heading fontSize="md">Comment</Heading>
                        <RadioGroup>
                            <Stack direction="column">
                                <RadioGroup>
                                    <Stack>
                                        <Radio value="visible">
                                            visible
                                        </Radio>
                                        <Radio value="hidden">
                                            hidden
                                        </Radio>
                                    </Stack>
                                </RadioGroup>
                            </Stack>
                        </RadioGroup>
                    </Box> */}
                </Box>
            </Flex>
        </Box>
        </>
    )
}
export default EditOffers