// ** Define the data that will be stored in context
let user = localStorage.getItem("user")
	? JSON.parse(localStorage.getItem("user"))
	: "";

// ** Define context initial state
export const initialState = {
	user: user,
	loading: false,
	toggleNavbar: true,
	currentProject: {},
	projects: [],
	companies: [],
	currentCompany : {},
	courses : []
};

// ** Define the context reducer
// ** you still don't know reducer? read this: https://reactjs.org/docs/hooks-reference.html#usereducer , and buy some ram
export const GlobalReducer = (initialState, action) => {
	switch (action.type) {
		case "REQUEST_LOGIN":
			return {
				...initialState,
				loading: true,
			};

		case "LOGIN_SUCCESS":
			return {
				...initialState,
				user: action.payload.user,
				loading: false,
			};

		case "LOGIN_ERROR":
			return {
				...initialState,
				loading: false,
				errorMessage: action.error,
			};

		case "LOGOUT":
			return {
				...initialState,
				user: "",
				token: "",
			};

		case "AUTH_STATUS":
			return {
				...initialState,
				isLoggedin: action.payload.isLoggedin,
				uid: action.payload.uid,
			};

		case "PROJECTS":
			return {
				...initialState,
				projects: action.payload
			}
		case "CURRENT_PROJECTS":
			return {
				...initialState,
				currentProject: { id: action.payload.id, name: action.payload.name },
			}
		case "CURRENT_COMPANY":
			return {
				...initialState,
				currentCompany: { id: action.payload.id, name: action.payload.name },
			}
		case "COMPANIES":
			return {
				...initialState,
				companies: action.payload
			}
		case "COURSES":
			return {
				...initialState,
				courses: action.payload
			}

		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};
