import {
    Box,
    Heading,
    Stack,
    Input,
    Text,
    HStack,
    Spinner,
    Button,
    Spacer,
    SimpleGrid,
    VStack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { getCollectionFirebase, getSingleDocumentFirebase } from '../../Apis/firebaseApi';
import { useGlobalDispatch, useGlobalState } from '../../Hooks/Contexts';
import { useNavigate } from 'react-router-dom';

const ProjectsPage = () => {
    const [project, setProject] = useState('');
    const [loading, setLoading] = useState('');
    const dispatch = useGlobalDispatch();
    const globalState = useGlobalState();
    const navigate = useNavigate();

    const handleChangeProject = async (project) => {
        dispatch({
            type : 'CURRENT_PROJECTS',
            payload : {
                id : project.id,
                name : project.name
            }
        })
    };

    const dummy = [
        // {
        //     name: "project kuda",
        //     id: "1i42voh1424pvbhj1"
        // },
        // {
        //     name: "project lele",
        //     id: "o1n4kn12on1o1lk4"
        // },
        // {
        //     name: "project 101",
        //     id: "u1dg2iyr4dp12"
        // },
        // {
        //     name: "singing course",
        //     id: "l1k3n;124b1j212"
        // },
        // {
        //     name: "cooking 101",
        //     id: "o1n4kn12on1o1lk4"
        // },
    ]


    return (
        <Stack position='relative' w='full' h='100vh'>

            {globalState && globalState.projects?.length > 0 ?

                <SimpleGrid gap={{ base: 1, md: 1.5, xl: 2 }} columns={{ base: 1, lg: 2, xl: 4 }}>
                    {globalState.projects && globalState.projects?.length > 0 ? globalState?.projects?.map((project, i) => (
                        <Box
                            cursor='pointer'
                            _hover={{
                                transform: 'scale(1.1)',
                                transition: "60ms linear",
                            }}
                            bg='white'
                            shadow='md'
                            p={5}
                            borderRadius='xl'
                            key={i}
                            borderWidth={project.id === globalState.currentProject.id ? '2px' : ''}
                            borderColor={project.id === globalState.currentProject.id ? 'red' : ''}
                            onClick={()=>handleChangeProject(project)}
                        >
                            <Text color="blackAlpha.800" fontWeight='bold'>{project.name}</Text>
                            <Spacer />
                            <Text color="blackAlpha.500" fontSize={10}>ID: {project.id}</Text>
                        </Box>
                    )) : null}
                </SimpleGrid>

                :
                <Box h='100%' w='100%' display='flex' flexDir='column' justifyContent='center' alignItems='center'>
                    <VStack gap={4}>
                        <Heading size='md' color="blackAlpha.800"> Oops, you seem haven't had any projects</Heading>
                        <Button colorScheme='blue' onClick={() => navigate('/projects/create-project')}>Add New Project</Button>
                    </VStack>
                </Box>}
        </Stack>
    )
}

export default ProjectsPage