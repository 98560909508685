import React from 'react'
import DashboardPage from '../Pages/Dashboard/DashboardPage'
import OffersPage from '../Pages/Offers/OffersPage'
import Courses from '../Pages/Courses/Courses'


const DashboardRouter = [
	{
		path: "/dashboard-examples",
		element: <DashboardPage />
	},
	// {
	// 	path: "/dashboard",
	// 	element: <ExamplePage />
	// },
	{
		path: "/dashboard/:id",
		element: <DashboardPage />
	},
]

export default DashboardRouter