// Import the functions you need from the SDKs you need
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

import song from "../Assets/Sound/CoinDrop-Notification.mp3";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBmM8nIPrwMAZQniYb7E1wRt5Ak1RnBB0E",
//   authDomain: "edrus-lms.firebaseapp.com",
//   projectId: "edrus-lms",
//   storageBucket: "edrus-lms.appspot.com",
//   messagingSenderId: "972797310737",
//   appId: "1:972797310737:web:f6521a3db5452b41597eb0",
//   measurementId: "G-1GXTXGDYG5"
// };

// //deoapp
// const firebaseConfig = {
// 	apiKey: "AIzaSyC979BfEo747GyDPpY5de_w9DtjdGi9FtQ",
// 	authDomain: "deoappcom.firebaseapp.com",
// 	projectId: "deoappcom",
// 	storageBucket: "deoappcom.appspot.com",
// 	messagingSenderId: "897765531017",
// 	appId: "1:897765531017:web:5387c754b64adfecc51558",
// 	measurementId: "G-RMQERMYJ7T"
// };

//deoapp-indonesia
const firebaseConfig = {
	apiKey: "AIzaSyDHwfzgKXqfknEy3bctrbrlu37_hKeJevo",
	authDomain: "deoapp-indonesia.firebaseapp.com",
	projectId: "deoapp-indonesia",
	storageBucket: "deoapp-indonesia.appspot.com",
	messagingSenderId: "814589130399",
	appId: "1:814589130399:web:a0bb255936eefd57e554aa",
	measurementId: "G-B9FPJL2RD0"
};

// Initialize Firebase

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const configMessage = getMessaging(app);
auth.languageCode = "id";

export { app, analytics, auth, db, storage };
export const fetchToken = async (setTokenId) => {
	try {
		const token = await getToken(configMessage, {
			vapidKey: firebaseConfig.token_option,
		});
		if (token) {
			// console.log(token, "this is push notif token");
			setTokenId(token);
		} else {
			console.log("no push notif token for now");
		}
	} catch (error) {}
};

export const onMessageListener = (toast) => {
	onMessage(configMessage, (payload) => {
		const notif = new Audio(song);
		notif.play();
		const { data } = payload;
		const { title, description } = data;
		toast({
			title: title,
			description: description,
			position: "top-right",
			isClosable: true,
		});
	});
};
