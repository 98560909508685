import {
    Badge,
    Button,
    Checkbox,
    HStack,
    Icon,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import { formatFrice } from "../../Utils/numberUtil";
import { AiOutlineEye } from "react-icons/ai";


export const ShippingTable = (props) => {
    const data = props?.data;
    const selectedRows = props?.selectedRows
    const setSelectedRows = props?.setSelectedRows


    const toggleRowSelection = (rowId) => {
        if (selectedRows.includes(rowId)) {
            setSelectedRows(selectedRows.filter((id) => id !== rowId));
        } else {
            setSelectedRows([...selectedRows, rowId]);
        };
    };

    const isRowSelected = (rowId) => selectedRows.includes(rowId);




    return (
        <>
            <Table {...props} variant="striped" bgcolor="white" size="sm" shadow={"md"}>
                <Thead>
                    <Tr>
                        <Th>
                            <Checkbox
                                isChecked={selectedRows?.length === data?.length}
                                onChange={() => {
                                    if (selectedRows?.length === data?.length) {
                                        setSelectedRows([]);
                                    } else {
                                        setSelectedRows(data.map((item) => item));
                                    }
                                }}
                            />
                        </Th>

                        <Th>Date</Th>
                        <Th>Transaction</Th>
                        <Th>order id</Th>
                        <Th>Total</Th>
                        <Th>Payment Status</Th>
                        <Th>Actions</Th>
                        {/* <Th>Actions 2</Th> */}
                    </Tr>
                </Thead>
                <Tbody>
                    {data?.map((x, index) => {

                        return (
                            <Tr onClick={() => console.log(x)} key={index}>
                                <Td>
                                    <Checkbox
                                        isChecked={isRowSelected(x)}
                                        onChange={() => toggleRowSelection(x)}
                                    />
                                </Td>
                                <Td fontSize="2xs">
                                    <Text fontWeight={500}>
                                        {x?.createdAt?.toDate()?.toDateString() || x?.lastUpdated?.toDate()?.toDateString()}
                                    </Text>
                                </Td>

                                <Td>
                                    <Stack w={'300px'}>
                                        <Text fontSize={12}>
                                            <strong>{x?.product || "product name not found"}</strong>
                                        </Text>
                                        <Text fontSize={12}>NO. RESI : {x?.requestCredential?.cnote_no}</Text>
                                    </Stack>
                                </Td>


                                <Td>
                                    <Stack>
                                        <Text fontSize={12}>Name : <strong>{x?.name || x?.firstName + x?.lastName}</strong></Text>
                                        <Text fontSize={12}>Phone : <strong>{x?.phone || x?.mobilePhone || x?.phoneNumber}</strong></Text>
                                    </Stack>
                                </Td>


                                <Td>
                                    <HStack>
                                        <Text color="muted" fontSize={"sm"} textTransform='uppercase' fontWeight={500}>
                                            {x.paymentMethod}
                                        </Text>

                                    </HStack>
                                    <Text color={"green.500"} fontSize={"sm"} fontWeight={500}>
                                        Rp.
                                        {formatFrice(x?.opportunity_value)}
                                    </Text>
                                </Td>

                                <Td>
                                    <Badge
                                        textAlign={'center'}
                                        p={1}
                                        borderRadius='md'
                                        colorScheme={x?.paymentStatus === "PENDING" ? "red" : x?.paymentStatus === "PAID" ? "green" : "red"}
                                    >
                                        {x?.paymentStatus || "null"}
                                    </Badge>
                                </Td>

                                <Td>
                                    <HStack spacing="1">
                                        {/* <IconButton
                                            icon={<AiOutlineEye fontSize="1.25rem" />}
                                            variant="ghost"
                                            aria-label="Edit member"
                                            onClick={() => console.log(x.expected_amount)}
                                        /> */}
                                        <Icon as={AiOutlineEye} />
                                    </HStack>
                                </Td>
                                {/* <Td>
                                    <Button onClick={() => console.log(x)}>Cetak Label</Button>
                                </Td> */}
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>

        </>
    );
};
