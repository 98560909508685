import Courses from "../Pages/Courses/Courses";
import SingleCourse from "../Pages/Courses/SingleCourse";
import EditLesson from "../Pages/Lessons/EditLesson";
import Pages from "../Pages/Pages/Pages";
import CreatePage from "../Pages/Pages/CreatePage";
import CreateCourse from "../Pages/Courses/CreateCourse";
import ThemesEditPage from "../Pages/Products/ThemesEditPage";
import CurrentThemes from "../Pages/Products/CurrentThemePage";
import DomainsPage from "../Pages/Products/DomainsPage";
import NewsPage from "../Pages/Products/NewsPage";
import CreateNewsPage from "../Pages/Products/CreateNewsPage";
import ProjectsPage from "../Pages/Projects/ProjectsPage";
import CreateProjects from "../Pages/Projects/CreateProjects";
import OffersPage from "../Pages/Offers/OffersPage";
import EditOffers from "../Pages/Offers/EditOffers";
import { Navigate } from "react-router-dom";
import ListingsPage from "../Pages/Listings/QrListPage";
import TransactionPage from "../Pages/Transactions/Transactions";
import RedirectToAdmin from "../Pages/Redirect/RedirectToAdmin";
import PromptListPage from "../Pages/Prompts/PromptListPage";
import PromptFileListPage from "../Pages/Prompts/PromptFileListPage";
import ShippingDashboard from "../Pages/Dashboard/ShippingDashboard";
import LabelPrint from "../Pages/Transactions/LabelPrint";

const PagesRouter = [
	{
		path : "/login",
		element : <Navigate to="/" />
	},
	{
		path: "/",
		element: <TransactionPage />,
	},
	{
		path: "/courses",
		element: <Courses />,
	},
	{
		path: "/qr",
		element: <ListingsPage />,
	},
	{
		path: "/courses/create",
		element: <CreateCourse />,
	},
	{
		path: "/courses/:id_course",
		element: <SingleCourse />,
	},
	{
		path: "/courses/:id_course/lesson/:id_lesson",
		element: <EditLesson />,
	},
	{
		path: "/pages",
		element: <Pages />,
	},
	{
		path: "/pages/create",
		element: <CreatePage />,
	},
	{
		path: "/themes",
		element: <CurrentThemes />,
	},
	{
		path: "/themes/edit",
		element: <ThemesEditPage />,
	},
	{
		path: "/domains",
		element: <DomainsPage />,
	},
	{
		path: "/news",
		element: <NewsPage />,
	},
	{
		path: "/news/create",
		element: <CreateNewsPage />,
	},
	{
		path: "/projects",
		element: <ProjectsPage />,
	},
	{
		path: "/projects/create-project",
		element: <CreateProjects />,
	},
	{
		path: "/offers",
		element: <OffersPage />
	},
	{
		path: "offers/:id",
		element: <EditOffers />
	},
	{
		path: "offers/:id",
		element: <EditOffers />
	},
	{
		path: "transactions",
		element: <TransactionPage />
	},
	{
		path: "redirect-to-admin",
		element: <RedirectToAdmin />
	},
	{
		path: "prompts",
		element: <PromptListPage />
	},
	{
		path: "prompts/:id",
		element: <PromptFileListPage />
	},
	{
		path: "prompts/:id/:fileId",
		element: <PromptFileListPage />
	},
	{
		path: "shipping",
		element: <ShippingDashboard />
	},
	{
		path: "print-resi",
		element: <LabelPrint />
	},

];

export default PagesRouter;
