import {
	Avatar,
	Box,
	Button,
	Container,
	Divider,
	Flex,
	FormControl,
	FormLabel,
	Input,
	Progress,
	Stack,
	StackDivider,
	Text,
} from '@chakra-ui/react'
import { useCallback, useEffect, useMemo, useState } from 'react';
import {  updateProfileFirebase, uploadFileFirebase } from '../../Apis/firebaseApi';
import { auth } from '../../Config/firebase';
import { useDropzone } from 'react-dropzone';

export const SettingAccountPage = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		name: '',
		phone: '',
		photoURL: '',
		profileImagePath : ''
	});
	const user = auth.currentUser;

	const handleUpdate = async () => {
		console.log(data);
		await updateProfileFirebase(data);
	};

	const handleDeletePhoto = async () => {
		// Create a reference to the file to delete


		console.log(data.profileImagePath);
		
		const example = "user/BA5K63d7ViYGzf1BCTBZZa9y6C22/thumbnail/Screenshot 2023-06-07 at 12.01.55_200x200.png";

		console.log(example);

		// const itemRef = ref(storage, data.profileImagePath);

		// // // Delete the file
		// deleteObject(itemRef).then(() => {
		// 	// File deleted successfully
		// 	console.log('success deleting from storage');
		// }).then(() => { })
		// 	.catch(error => console.log(error.message, "error when deleting storage"))

		//deleting from firestore
		// setDocumentFirebase(
		// 	`courses/${params.id_course}/lessons/`,
		// 	params.id_lesson,
		// 	{
		// 		...lesson,
		// 		media: null,
		// 		mediaPath: '',
		// 		mediaType: '',
		// 		mediaTitle: ''
		// 	},
		// 	currentProject.id)

		// 	.then((res) => {
		// 		console.log(res)

		// 		//deleting from state
		// 		setLesson({
		// 			...lesson,
		// 			media: null,
		// 			mediaPath: '',
		// 			mediaType: '',
		// 			mediaTitle: ''
		// 		})
		// 	}).catch(e => console.log(e.message))
	};


	const handleDropImage = async (file) => {
		const result = await uploadFileFirebase(file, null, setLoading, null, {type : null});
		if (result) {
			console.log({
				...data,
				profileImagePath: result.path,
				photoURL: result.image_url,
			});
			setData({
				...data,
				profileImagePath: result.path,
				photoURL: result.image_url,
			});
		};
	};

	function MyDropzone(props) {
		const baseStyle = {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			padding: '20px',
			borderWidth: 2,
			borderRadius: 2,
			borderColor: '#eeeeee',
			borderStyle: 'dashed',
			backgroundColor: '#fafafa',
			color: '#bdbdbd',
			outline: 'none',
			transition: 'border .24s ease-in-out'
		};

		const focusedStyle = {
			borderColor: '#2196f3'
		};

		const acceptStyle = {
			borderColor: '#00e676'
		};

		const rejectStyle = {
			borderColor: '#ff1744'
		};

		const onDrop = useCallback(async acceptedFiles => {
			// Do something with the files
			await handleDropImage(acceptedFiles[0]);
		}, [])

		const {
			getRootProps,
			getInputProps,
			isFocused,
			isDragAccept,
			isDragReject
		} = useDropzone({ onDrop });

		const style = useMemo(() => ({
			...baseStyle,
			...(isFocused ? focusedStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {})
		}), [
			isFocused,
			isDragAccept,
			isDragReject
		]);



		return (
			<div className="container">
				<div {...getRootProps({ style })}>
					<input {...getInputProps()} />
					<p>Drag drop you video course here, or click to select files</p>
					{loading !== 0 && loading !== 100 ? <p>progress is {loading}</p> : <></>}
				</div>
			</div>
		);
	};

	useEffect(() => {
		return () => {
		}
	}, []);

	return (
		<Box as="section"
			py={{
				base: '4',
				md: '8',
			}}>
			<Container
				py={{
					base: '4',
					md: '8',
				}}
			>
				{loading !== 0 && loading !== 100 ? <Progress value={loading} /> : null}
				<Stack spacing="5">
					<Stack
						spacing="4"
						direction={{
							base: 'column',
							sm: 'row',
						}}
						justify="space-between"
					>
						<Box>
							<Text fontSize="lg" fontWeight="medium">
								Your Profile
							</Text>
							<Text color="muted" fontSize="sm">
								Tell others who you are
							</Text>
						</Box>
					</Stack>
					<Divider />
					<Stack spacing="5" divider={<StackDivider />}>
						<FormControl id="name">
							<Stack
								direction={{
									base: 'column',
									md: 'row',
								}}
								spacing={{
									base: '1.5',
									md: '8',
								}}
								justify="space-between"
							>
								<FormLabel variant="inline">Name</FormLabel>
								<Input
									onChange={(e) => setData({ ...data, displayName: e.target.value, name: e.target.value })}
									maxW={{
										md: '3xl',
									}}
									defaultValue={user?.displayName ? user.displayName : ''}
								/>
							</Stack>
						</FormControl>
						<FormControl id="email">
							<Stack
								direction={{
									base: 'column',
									md: 'row',
								}}
								spacing={{
									base: '1.5',
									md: '8',
								}}
								justify="space-between"
							>
								<FormLabel variant="inline">Email</FormLabel>
								<Input
									type="email"
									disabled
									maxW={{
										md: '3xl',
									}}
									onChange={(e) => setData({ ...data, name: e.target.value })}
									defaultValue={user?.email ? user.email : ''}
								/>
							</Stack>
						</FormControl>
						<FormControl id="phone">
							<Stack
								direction={{
									base: 'column',
									md: 'row',
								}}
								spacing={{
									base: '1.5',
									md: '8',
								}}
								justify="space-between"
							>
								<FormLabel variant="inline">Phone</FormLabel>
								<Input
									type='number'
									onChange={(e) => setData({ ...data, phoneNumber: e.target.value })}
									maxW={{
										md: '3xl',
									}}
									defaultValue={user?.phoneNumber ? user.phoneNumber : ''}
								/>
							</Stack>
						</FormControl>
						<FormControl id="picture">
							<Stack
								direction={{
									base: 'column',
									md: 'row',
								}}
								spacing={{
									base: '1.5',
									md: '8',
								}}
								justify="space-between"
							>
								<FormLabel variant="inline">Photo</FormLabel>
								<Stack
									spacing={{
										base: '3',
										md: '5',
									}}
									direction={{
										base: 'column',
										sm: 'row',
									}}
									width="full"
									maxW={{
										md: '3xl',
									}}
								>
									<Stack>
										{data? <Avatar
											size="lg"
											name={user.email}
											src={ data?.photoURL ? data?.photoURL : user.photoURL ? user.photoURL : ''}
										/>: <></>}
										
										<Button onClick={()=>handleDeletePhoto()}>Remove</Button>
									</Stack>
									{/* <DropZoneComponent width="full" /> */}
									<MyDropzone />
									{/* <Input type='file' onChange={e => handleDropImage(e.target.files[0])} /> */}
								</Stack>
							</Stack>
						</FormControl>
						{/* <FormControl id="website">
							<Stack
								direction={{
									base: 'column',
									md: 'row',
								}}
								spacing={{
									base: '1.5',
									md: '8',
								}}
								justify="space-between"
							>
								<FormLabel variant="inline">Website</FormLabel>
								<InputGroup
									maxW={{
										md: '3xl',
									}}
								>
									<InputLeftAddon>https://</InputLeftAddon>
									<Input defaultValue="www.chakra-ui.com" />
								</InputGroup>
							</Stack>
						</FormControl>
						<FormControl id="bio">
							<Stack
								direction={{
									base: 'column',
									md: 'row',
								}}
								spacing={{
									base: '1.5',
									md: '8',
								}}
								justify="space-between"
							>
								<Box>
									<FormLabel variant="inline">Bio</FormLabel>
									<FormHelperText mt="0" color="muted">
										Write a short introduction about you
									</FormHelperText>
								</Box>
								<Textarea
									maxW={{
										md: '3xl',
									}}
									rows={5}
									resize="none"
								/>
							</Stack>
						</FormControl> */}

						<Flex direction="row-reverse">
							<Button onClick={() => handleUpdate()}>Save</Button>
						</Flex>
					</Stack>
				</Stack>
			</Container>
		</Box>
	)
}