import {
    Button, Flex, Heading, useDisclosure, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    Text,
    Box,
    HStack,
} from '@chakra-ui/react'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'
import React, { useEffect, useState } from 'react'
import { arrayUnionFirebase, getSingleDocumentFirebase } from '../../Apis/firebaseApi';
import { useGlobalState } from '../../Hooks/Contexts';

const DomainsPage = () => {
    const { onOpen, isOpen, onClose } = useDisclosure();
    const [domain, setDomain] = useState('');
    const [currentDomains, setCurrentDomains] = useState([]);
    const { currentProject } = useGlobalState();


    const getDomains = async () => {
        const result = await getSingleDocumentFirebase('pages', currentProject.id);
        if (result) setCurrentDomains(result.domains);
    };

    const handleSave = async () => {
        await arrayUnionFirebase('pages', currentProject, 'domains', [domain]);
        onClose();
    };

    useEffect(() => {
        getDomains();
    }, []);

    return (
        <>
            <Flex justifyContent='space-between'>
                <Heading size='md'>Domains</Heading>
                {currentDomains?.length === 2 || currentDomains?.length > 2 ? null : <Button colorScheme='green' onClick={onOpen}>Add New Domain</Button>}
            </Flex>
            <Box padding={5} mt={5}>

                            {currentDomains?.length > 0 && currentDomains ? currentDomains?.map((x, i) => (
                                <Flex bg='white' borderRadius={10}key={i} p={4} justifyContent='space-between' borderBottomWidth={2}>
                                    <Text>{x}</Text>
                                    <HStack>
                                        <Button size="xs" colorScheme='red'><FiTrash2 size={10}/></Button>
                                        <Button size="xs" colorScheme='green'><FiEdit2 size={10}/></Button>
                                    </HStack>
                                </Flex>
                                
                                ))
                                :
                                null}

            </Box>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add New Domain</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input placeholder='enter domain here' onChange={e => setDomain(e.target.value)} />
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={handleSave}>
                            Submit
                        </Button>
                        <Button variant='ghost' onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DomainsPage