import { Box, Button, HStack, Heading, Image, Stack, Text } from '@chakra-ui/react'
import React from 'react';
import { motion } from "framer-motion";


const Welcome = ({ page, setPage, data, setData, x , setX }) => {
	return (
		<motion.div                            //updated the div tag
			initial={{ x: x }}
			transition={{ duration: 0.5 }}
			animate={{ x: 0 }}
		>
			<Stack h='100vh' display='flex' flexDir='column' justifyContent='center' alignItems='center'>
				<Box bg='white' p={20} display='flex' flexDir='column' justifyContent='center' alignItems='center' gap={5}>
					<Heading color='blackAlpha.800'>Welcome!</Heading>
					<HStack>
						<Text fontWeight={600} color='blackAlpha.800'>Welcome to </Text>
						<Image maxW="40" src={require('../../../Assets/Logo/64174d501639e03e287e2160.png')} />
					</HStack>
					<Text fontWeight={400} color='blackAlpha.800'>Start Making Your Own Courses and Sell to your audiences</Text>
					<Button colorScheme='blue' onClick={() => {setPage(page + 1); setX(1000)}}>Next</Button>
				</Box>
			</Stack>
		</motion.div>

	)
}

export default Welcome