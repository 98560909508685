import axios from "axios";
import { getCollectionFirebase } from "../../Apis/firebaseApi";

export async function LoginUser(dispatch, loginPayload) {
  const { email, password } = loginPayload;
  try {
    dispatch({ type: "REQUEST_LOGIN" });
    // const response = await axios.post("https://reqres.in/api/login", {
    //   email,
    //   password
    // });
    const response = await axios.post("https://reqres.in/api/login", {
      email,
      password,
    });
    // console.log(response);


    dispatch({ type: "LOGIN_SUCCESS", payload: response.data });
    localStorage.setItem("currentUser", JSON.stringify(response.data));
    return response.data;
    
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
  }
}


export async function getCourses(projectsId, dispatch) {
  const conditions = [{field : 'projectsId', operator : '==', value : projectsId }]
  try {
    const response = await getCollectionFirebase('courses', {conditions}, {sortBy : null}, {limitValue : null})
    if (response?.length > 0) {
      dispatch({
        type : 'COURSES',
        payload : response
      })
    }
  } catch (error) {
    throw new Error(error.message)
  }
}
