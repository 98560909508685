import React, { useState } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	FormControl,
	FormLabel,
	Input,
	Textarea,
	Select,
	HStack,
	Spinner,
	Text,
	useToast,
	Stack,
	Center,
} from "@chakra-ui/react";
import {
	addDocumentFirebase,
	deleteDocumentFirebase,
	getCollectionFirebase,
	updateDocumentFirebase,
} from "../../Apis/firebaseApi";
import { UploadBlob } from "../../Utils/Upload";
import { arrayUnionFirebase } from "../../Apis/firebaseApi";
import { useGlobalState } from "../../Hooks/Contexts/contexts";
import { auth, db } from "../../Config/firebase";
import { useParams } from "react-router-dom";
import { deleteDoc, doc } from "firebase/firestore";


const Modals = (props) => {
	const { isOpen, onClose, datas, navigate, update, setUpdate, type, getData } = props;
	const [price, setPrice] = useState("free");
	const [input, setInput] = useState({});
	const [loading, setLoading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [isUploading, setIsUploading] = useState(false);
	const { currentProject } = useGlobalState();
	const uid = auth?.currentUser?.uid;
	const toast = useToast();
	const params = useParams();


	const handleSave = async () => {
		setLoading(true);
		if (datas?.type === "addCourse") {
			try {
				const id = await addDocumentFirebase("courses", {
					...input,
					price: price,
					projectsId: currentProject?.id || null,
					createdAt: new Date(),
					courseType: datas?.course_type
				})
				navigate(`/courses/full-course/${id}`);
			} catch (error) {
				console.log(error.message, "error while adding course")
			} finally {
				setLoading(false)
			}

		} else if (datas?.type === "section") {
			try {
				await arrayUnionFirebase("courses", datas?.id, "sections", [
					{ title: input.title },
				]);
				setUpdate(!update);
				onClose();
			} catch (error) {
				console.log(error.message, "error when adding section---array union")
			} finally {
				setLoading(false)
			}

		} else if (datas?.type === "lesson") {

			try {
				const res = await addDocumentFirebase(`courses/${datas?.id}/lessons`, {
					title: input.title,
					section: datas?.course,
				});
				arrayUnionFirebase("courses", datas?.id, "lessons", [
					{
						title: input.title,
						section: datas?.course,
						id: res
					},
				]).then(() => {

				}).catch(error => {
					console.log(error.message, "error when array union lessons")
				})

				setUpdate(!update);
				onClose();
			} catch (error) {
				console.log(error.message, "error when adding lesson")
			} finally {
				setLoading(false)
			}
		}
	};

	const handleDelete = async (type) => {
		if (type === "deleteCourse") {
			setLoading(true)

			try {
				await deleteDocumentFirebase("courses", datas?.id);
				setUpdate(!update);
				onClose();
			} catch (error) {
				toast({
					title: 'Error deleting course',
					description: error.message,
					status: 'error',
					isClosable: true,
					duration: 9000
				})
			} finally {
				getData();
				setLoading();
			}
		} else if (type === "deleteLesson") {
			setLoading(true)


			const updatedLessonArray = datas?.course?.lessons?.filter(obj => obj.id !== datas?.lesson?.id)

			try {
				updateDocumentFirebase('courses', params.id_course, {
					lessons: updatedLessonArray
				}).then(() => {

				}).catch(error => {
					console.log(error.message, "---error when deleting lesson")
				})
				await deleteDocumentFirebase(`courses/${params.id_course}/lessons`, datas?.lesson?.id);
				setUpdate(!update);
				onClose();
				setLoading(false);

			} catch (error) {
				toast({
					title: 'Error deleting lesson',
					description: error.message,
					status: 'error',
					isClosable: true,
					duration: 9000
				})
			} finally {
				setLoading(false);
			}
		} else {
			console.log(type)
		}
	};

	const handleClose = () => {
		onClose();
		setLoading(false);
	};

	const handleUpload = async (file) => {
		if (loading) {
		}
		setIsUploading(true);

		if (datas?.type === "changeThumbnail") {
			UploadBlob(file, uid, "courses", file.name, setProgress).then(
				(uploadedImg) => {
					// setInput({
					// 	thumbnail: uploadedImg.url.replace(/(\.[^.\/\\]+)$/i, '_800x800$1'),
					// });
					const updateData = {
						thumbnail: uploadedImg.url.replace(/(\.[^.\/\\]+)$/i, '_800x800$1')
					}

					console.log(uploadedImg);

					updateDocumentFirebase('courses', params?.id_course, updateData).then(() => {
						setIsUploading(false);
						onClose();
						navigate(-1)
					 })
						.catch((error) => {
							toast({
								title: "Error",
								description: "error saving thumbnail : " + error.message,
								isClosable: true,
								duration: 9000,
								status: "error"
							})
						})
					setIsUploading(false);
				}
			);
		} else {
			UploadBlob(file, uid, "courses", file.name, setLoading).then(
				(uploadedImg) => {
					console.log(uploadedImg, "this is data result");
					setInput({
						...input,
						thumbnail: uploadedImg.url.replace(/(\.[^.\/\\]+)$/i, '_800x800$1'),
					});
					setIsUploading(false);
				}
			);
		}

	};

	const UploadingComponent = () => (
		<HStack>
			<Spinner color="teal" size="xs" />
			<Text color="teal">Uploading...</Text>
		</HStack>
	);

	return (
		<Modal isOpen={isOpen} onClose={() => handleClose()} size="2xl">
			<ModalOverlay />
			<ModalContent bg='gray.100'>
				<ModalHeader textTransform="capitalize">
					{type === "delete" ? `Delete course ${datas?.title}` : datas?.title}
				</ModalHeader>
				<ModalCloseButton />

				<ModalBody bg='gray.100'>
					{datas?.type === "addCourse" ?
						(
							<Stack>
								<FormControl>
									<FormLabel>Title</FormLabel>
									<Input
										bg='white'
										type="text"
										placeholder="Input your course title"
										onChange={(e) =>
											setInput({
												...input,
												title: e.target.value,
											})
										}
									/>
								</FormControl>
								<FormControl>
									<FormLabel>Type</FormLabel>
									<Input
										bg='white'
										type="text"
										placeholder="Input your course title"
										defaultValue={
											datas?.course_type === "full_course" ?
												"Full Course" : "Mini Course"
										}
										disabled
									/>
								</FormControl>
								<FormControl>
									<FormLabel>Desctiption</FormLabel>
									<Textarea
										bg='white'
										placeholder="Input description of your course"
										onChange={(e) =>
											setInput({
												...input,
												description:
													e.target.value,
											})
										}
									/>
								</FormControl>
								<FormControl>
									<FormLabel>Price</FormLabel>
									<Select
										bg='white'
										onChange={(e) =>
											setPrice(e.target.value)
										}
									>
										<option value="free">Free</option>
										<option value="paid">Paid</option>
									</Select>
								</FormControl>
								<FormControl>
									<FormLabel>
										Thumbnail (optional)
									</FormLabel>
									<Input
										bg='white'
										type="file"
										variant="ghost"
										onChange={(e) => handleUpload(e.target.files[0])}
									/>
								</FormControl>
								{isUploading ? <UploadingComponent /> : null}
							</Stack>
						) : datas?.type === "section" || datas?.type === "lesson" ?
							(
								<>
									<FormControl>
										<FormLabel>Input</FormLabel>
										<Input
											bg='white'
											type="text"
											placeholder={`Input your ${datas?.title} title`}
											onChange={(e) =>
												setInput({
													...input,
													title: e.target.value,
												})
											}
										/>
									</FormControl>
								</>
							) : type === "deleteCourse" ?
								(
									<Center>
										<Text>
											Are you sure want to delete course{" "}
											<b> {datas?.title}</b>? Deleting will remove all the sections and videos inisde
											{<br />}
											{<br />}
											{<br />}
											<b style={{ color: "#cc2400" }}>WARNING!! </b>This process cannot be undone.
										</Text>
									</Center>
								) : datas?.type === "deleteLesson" ?
									(
										<>
											<Text fontWeight='bold'>Delete {datas?.lesson?.title} ?</Text>
											<Text>
												{JSON.stringify((datas?.course?.lessons?.filter(obj => obj.id !== datas?.lesson?.id)))}
											</Text>
											<Text>remove from array lessons, remove doc lesson</Text>
										</>
									)
									:
									datas?.type === "changeThumbnail" ?
										(
											<>
												<Text textAlign="center" fontWeight='bold'>Choose Thumbnail From Your Directory:</Text>
												<Input my={2} type="file"
													onChange={(e) => handleUpload(e.target.files[0])
													}
												/>
											</>
										)
										:
										<></>
					}
				</ModalBody>

				<ModalFooter>
					{type === "deleteCourse" ?
						<Button
							isLoading={loading}
							isDisabled={loading}
							loadingText='Deleting...'
							colorScheme="red"
							onClick={() => handleDelete("deleteCourse")}
							size='sm'
							variant='ghost'
						>
							Yes, delete
						</Button>
						: datas?.type === "deleteLesson" ?
							<Button
								colorScheme='blackAlpha'
								isLoading={loading}
								isDisabled={loading}
								loadingText='Saving...'
								onClick={() => handleDelete(datas?.type)}
							>
								Delete Lesson
							</Button>
							:
							<Button
								isLoading={loading}
								isDisabled={loading}
								loadingText='Saving...'
								colorScheme="green"
								onClick={() => handleSave()}
							>
								Save
							</Button>
					}
					{datas?.type !== "deleteCourse" ? (
						<Button
							isLoading={loading}
							isDisabled={loading}
							loadingText='Saving...'
							colorScheme="red"
							ml={3}
							onClick={() => handleClose()}
						>
							Cancel
						</Button>
					) : (
						null
					)}

				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default Modals;
