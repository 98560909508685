import React from "react";

import { ListDragableComponent } from "../../Components/Lists/ListDragableComponent";
import BreadCrumbComponent from "../../Components/BreadCrumbs/BreadCrumbComponent";
import { Box, Button, Grid, GridItem, Heading } from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";

function CreatePage() {
	const data = [
		{ title: "Home", link: "/" },
		{ title: "Pages", link: "/pages" },
		{ title: "Create Page", link: "/pages/create" },
	];

	return (
		<>
			<BreadCrumbComponent data={data} />

			<Grid templateColumns={"60% 40%"} gap="2" my="2">
				<GridItem>
					<Button m="1" width="full">
						Add Block
					</Button>
					<Button m="1" width="full">
						Add Header
					</Button>
					<Button width="full" m="1" mb="3" colorScheme="green">
						Save Page
					</Button>
					<ListDragableComponent />
				</GridItem>
				<GridItem>
					<Heading my="2" textAlign="center">
						Overview Page
					</Heading>
					<Box
						width="full%"
						border="8px"
						borderColor="black"
						borderRadius="xl"
						p="2"
						overflowX="auto"
					></Box>
				</GridItem>
			</Grid>
		</>
	);
}

export default CreatePage;
