import {
	Box,
	Button,
	Flex,
	Heading,
	Input,
	SimpleGrid,
	Stack,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Image,
	VStack,
	HStack,
	Spinner,
	Select,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { RxCross2 } from 'react-icons/rx';
import { UploadBlob } from '../../Utils/Upload';
import { useGlobalState } from "../../Hooks/Contexts/contexts";
import { HexColorPicker } from "react-colorful";
import Swal from 'sweetalert2';
import { setDocumentFirebase } from '../../Apis/firebaseApi';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../Config/firebase';

const ThemesEditPage = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [data, setData] = useState({});
	const [progress, setProgress] = useState(0);
	const [color, setColor] = useState("");
	const [uploadingActive, setUploadingActive] = useState("");
	const [activeBrand, setActiveBrand] = useState(0);
	const [bannerList, setBannerList] = useState([]);
	const [uploadingOnIndex, setUploadingOnIndex] = useState(null);
	const { currentProject, currentCompany } = useGlobalState();

	const globalState = useGlobalState();
	const navigate = useNavigate();

	const colorSchemes = [
		"blackAlpha",
		"blue",
		"cyan",
		"facebook",
		"gray",
		"green",
		"linkedin",
		"messenger",
		"orange",
		"pink",
		"purple",
		"red",
		"teal",
		"telegram",
		"twitter",
		"whatsapp",
		"whiteAlpha"
	];

	const handleSave = async () => {
		if (progress) { }
		Swal.fire({
			title: 'Do you want to save the changes?',
			showDenyButton: false,
			showCancelButton: true,
			confirmButtonText: 'Save',
			denyButtonText: `Don't save`,
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				const updateData = bannerList.length === 0 ? { companiesId: currentCompany?.id, ...data }
					:
					{ ...data, banner: bannerList, companiesId: currentCompany?.id };
					console.log(auth.currentUser.uid)

				setDocumentFirebase(
					"pages",
					currentProject.id,
					updateData,
					currentProject.id)
					.then(response => {
						if (response) {
							Swal.fire('Saved!', '', 'success').then(() => {
								navigate('/themes')
							})
						}
					}).catch(error => {
						console.log(error.message)
					})
			} else if (result.isDenied) {
				Swal.fire('Changes are not saved', '', 'info')
			}
		})
	};

	const handleUpload = async (file, title, index) => {
		if (index === undefined) {
			// console.log("uploading for logo", index)
			setUploadingActive(title)
			const result = await UploadBlob(file, "pages", globalState.uid || "xx", file.name, setProgress)
			if (title === "logo_light") {
				setData({
					...data,
					logoLight: result.url.replace(/(\.[^.\/\\]+)$/i, '_800x800$1')
				});
				setUploadingActive("")
			} else if (title === 'favicon') {
				setData({
					...data,
					favicon: result.url.replace(/(\.[^.\/\\]+)$/i, '_800x800$1')
				});
				setUploadingActive("")
			}
			else {
				setData({
					...data,
					logoDark: result.url.replace(/(\.[^.\/\\]+)$/i, '_800x800$1')
				});
				setUploadingActive("")
			};
		} else {
			console.log('uploading for banner', index)
			setUploadingOnIndex(index)
			const result = await UploadBlob(file, "pages", globalState.uid || "xx", `banner_${file.name}`, setProgress)
			const newBannerList = [...bannerList];
			newBannerList[index].image = result.url.replace(/(\.[^.\/\\]+)$/i, '_800x800$1');
			if (result) setUploadingOnIndex(null);
		}
	};

	const handleModal = (type) => {
		setActiveBrand(type);
		onOpen();
	};

	const handleSaveModal = () => {
		setData({
			...data,
			brand: {
				...data.brand,
				[activeBrand]: color
			}
		});
		onClose();
	};

	const handleDeleteBanner = (i) => {
		let arr = []
		arr = bannerList
		if (arr.length > 1) {
			arr.splice(i, 1)
			setBannerList([...arr])
		} else {
			arr = []
			setBannerList([...arr])
		}
	};

	const handleInputBanner = (value, index) => {
		const newBannerList = [...bannerList];
		newBannerList[index].link = value;
		setBannerList([...newBannerList])
	};

	const UploadingComponent = () => (
		<HStack>
			<Spinner color='teal' size='xs' />
			<Text color='teal'>
				Uploading...
			</Text>
		</HStack>
	);

	return (
		<>
			<Heading size='md'>Theme</Heading>

			<Stack my={5}>
				<Heading size="sm">Logo & Branding</Heading>
				<Text size="sm">Add a custom logo and/or favicon, and adjust your school thumbnail</Text>
				<Box my={10}>
					<SimpleGrid columns={3} spacing={3} maxW="5xl">

						<Box shadow='md' bg='white' padding={2}>
							<Text fontWeight='bold'>Logo Light</Text>
							<Input type="file" onChange={e => handleUpload(e.target.files[0], "logo_light")} />
							<VStack>
								<Text>Preview:</Text>
								{uploadingActive === "logo_light" ?
									<UploadingComponent />
									: null}
							</VStack>
							<Image src={data.logoLight} />
						</Box>

						<Box shadow='md' bg='white' padding={2}>
							<Text fontWeight='bold'>Logo Dark</Text>
							<Input type="file" onChange={e => handleUpload(e.target.files[0], "logo_dark")} />

							<VStack>
								<Text>Preview:</Text>
								{uploadingActive === "logo_dark" ?
									<UploadingComponent />
									: null}
							</VStack>
							<Image src={data.logoDark} />
						</Box>
						<Box shadow='md' bg='white' padding={2}>
							<Text fontWeight='bold'>Favicon</Text>
							<Input type="file" onChange={e => handleUpload(e.target.files[0], "favicon")} />
							<VStack>
								<Text>Preview:</Text>
								{uploadingActive === "favicon" ?
									<UploadingComponent />
									: null}
							</VStack>
							<Image src={data.favicon} />
						</Box>
						<Box shadow='md' bg='white' padding={2}>
							<Text fontWeight='bold'>Website Name</Text>
							<Input type="text" onChange={(e) => setData({ ...data, webName: e.target.value })} />
						</Box>
					</SimpleGrid>
				</Box>
			</Stack>


			<Stack my={10}>
				<Heading size="sm">Color Presets</Heading>
				<Text size="sm">Choose Color palette for your brand</Text>
				<Box my={10}>
					<SimpleGrid columns={3} spacing={3} maxW="5xl">
						<Flex gap={10} shadow='md' bg='white' padding={2}>
							<Box borderWidth={1} aspectRatio={1} w="10" bg={data?.brand ? data?.brand[1] : ""}></Box>

							<Text>Brand 1 : {data?.brand ? data?.brand[1] : ""}</Text>
							<Button size="xs" onClick={() => handleModal(1)}>Change</Button>
						</Flex>
						<Flex gap={10} shadow='md' bg='white' padding={2}>
							<Box borderWidth={1} aspectRatio={1} w="10" bg={data?.brand ? data?.brand[2] : ""}></Box>
							<Text>Brand 2 : {data?.brand ? data?.brand[2] : ""}</Text>
							<Button size="xs" onClick={() => handleModal(2)}>Change</Button>
						</Flex>
						<Flex gap={10} shadow='md' bg='white' padding={2}>
							<Box borderWidth={1} aspectRatio={1} w="10" bg={data?.brand ? data?.brand[3] : ""}></Box>
							<Text>Brand 3 : {data?.brand ? data?.brand[3] : ""}</Text>
							<Button size="xs" onClick={() => handleModal(3)}>Change</Button>
						</Flex>
					</SimpleGrid>
					<Box my={5} maxW='md'>
						<Heading size="sm">ColorScheme : </Heading>
						<Flex alignItems='center' gap={10}>
							<Select bg='white' onChange={e => setData({
								...data,
								colorScheme: e.target.value
							})}>
								{colorSchemes.map((x, i) => (
									<option value={x} key={i}>{x}</option>
								))}
							</Select>
							<Button p={5} colorScheme={data.colorScheme || "blackAlpha"} borderWidth={2} borderColor="gray.600">{data.colorScheme || ""}</Button>
						</Flex>
					</Box>
				</Box>
			</Stack>


			{/* Domains */}
			{/* <Stack my={10}>
				<Heading size="sm">Domains</Heading>
				<Text size="sm">Choose Color palette for your brand</Text>
				<Box my={10}>
					<SimpleGrid columns={3} spacing={3} maxW="5xl">
						<Flex justifyContent='space-between' shadow='md' bg='white' padding={2}>
							<Heading size='sm'>rifqy.deoapp</Heading>
							<Button colorScheme='' variant='ghost'><RxCross2 /></Button>
						</Flex>
						<Flex justifyContent='space-between' shadow='md' bg='white' padding={2}>
							<Heading size='sm'>speedreading.id</Heading>
							<Button colorScheme='teal' variant='ghost'><RxCross2 /></Button>
						</Flex>
						<Button size='sm' onClick={onOpen}>Add New Domains</Button>
					</SimpleGrid>
				</Box>
			</Stack> */}

			<Stack my={10}>
				<Heading size="sm">Banners</Heading>
				<Text size="sm">Setup banner for your pages</Text>
				<Box my={10}>
					<SimpleGrid columns={3} spacing={3} maxW="5xl">
						{bannerList.map((item, i) => (
							<Stack key={i} shadow='md' bg='white' p={4}>

								{/* <Image alt={i} src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/201211130529126a0.jpg/480px-201211130529126a0.jpg" /> */}
								<Flex justifyContent='space-between' padding={2}>
									<Stack>
										<Heading size='sm'>Link :</Heading>
										<Input onChange={e => handleInputBanner(e.target.value, i)} value={bannerList[i].link} />
										<Heading size='sm'>Image :</Heading>
										<Input type="file" onChange={e => handleUpload(e.target.files[0], "banner", i)} />
										{i === uploadingOnIndex ?
											<UploadingComponent />
											: null}
										{bannerList[i].image ? <Image src={bannerList[i].image} alt="No Preview" /> : null}

									</Stack>
									<Button colorScheme='teal' variant='ghost' onClick={() => handleDeleteBanner(i)}>
										<RxCross2 />
									</Button>
								</Flex>
							</Stack>

						))}
						{/* <Flex justifyContent='space-between' shadow='md' bg='white' padding={2}> */}
						<Button size='sm' onClick={() => setBannerList([...bannerList, {}])}>Add New Banner</Button>
						{/* </Flex> */}
					</SimpleGrid>
				</Box>
			</Stack>


			<Flex justifyContent='flex-end'>
				<Button colorScheme='green' onClick={handleSave}>Save</Button>
			</Flex>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Brand {activeBrand}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Flex justifyContent='space-between'>
							<HexColorPicker color={color} onChange={setColor} />
							<Box>
								<Box aspectRatio={1} w="10" bg={color}></Box>
								<Text>{color}</Text>
							</Box>
						</Flex>
					</ModalBody>

					<ModalFooter>
						<Button
							colorScheme="green"
							onClick={handleSaveModal}
						>
							Save
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>

	)
}

export default ThemesEditPage