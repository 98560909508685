import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { getCollectionFirebase } from '../../Apis/firebaseApi';
import { useGlobalState } from '../../Hooks/Contexts';
import BasicCardComponent from '../../Components/Cards/BasicCardComponent';
import { useNavigate } from 'react-router-dom';

const PromptListPage = () => {
    const [listPrompts, setListPrompts] = useState([]);
    const values = useGlobalState();
    const navigate = useNavigate();

    const getPromptLists = async () => {
        try {
            const conditions = [{
                value: values?.currentCompany?.id || "46dZzWVfRCl9d9PSUV2O",
                operator: "==",
                field: "companyId"
            }]

            const result = await getCollectionFirebase('folders', { conditions }, { sortBy: null }, { limitValue: null });
            setListPrompts(result);
            console.log(result);
        } catch (error) {
            console.log(error.message, "error in getting prompt lists")
        }
    };

    useEffect(() => {
        getPromptLists();
    }, [])

    return (
        <Box w="full" h="100vh">
            <Heading>Prompt List Page</Heading>

            {listPrompts ? listPrompts?.map((item, index) => {
                return <Flex
                    _hover={{
                        transform: 'scale(1.04)',
                        transition: "60ms linear",
                    }}
                    onClick={()=>navigate(item?.id)}
                    bg='white'
                    borderRadius="2xl"
                    shadow="sm"
                    cursor='pointer'
                    p={5} my={2} borderBottomWidth={2} borderBottomColor="rgba(0,0,0,0.1)" key={index} w="full">
                    <Stack>
                        <Heading size="sm">{item?.name}</Heading>
                        <Text>ID : {item?.id}</Text>
                        <Text>Type : {item?.type}</Text>
                    </Stack>
                </Flex>
            }) : null}

        </Box>
    )
}

export default PromptListPage