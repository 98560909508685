import React from 'react'
import { useLocation } from 'react-router-dom'
import ReportTemplate from '../Dashboard/ReportTemplate';
import { Button, SimpleGrid } from '@chakra-ui/react';

const LabelPrint = () => {
	const { state } = useLocation();

	console.log("state", state);


	return (<>
		<SimpleGrid columns={2} w="auto">
			{state?.selectedRows?.map((x, i) => (
				<ReportTemplate key={i} data={x} dataAll={state?.data} />
			))}
		</SimpleGrid>
		<Button onClick={() => window.print()}>Print</Button>
	</>

	)
}

export default LabelPrint