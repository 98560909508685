import { Box, Button, Checkbox, CheckboxGroup, Flex, FormLabel, Heading, Progress, Stack } from '@chakra-ui/react'
import React, { useRef } from 'react';
import { motion } from "framer-motion";

const Features = ({ page, setPage, data, setData, x , setX}) => {
	const inputRef = useRef();

	const handleCheckboxChange = (checked, value) => {
		const arr = []
		console.log(checked, value);
		if (checked) {
			arr.push(value)

		} else if (!checked) {

			const index = arr.findIndex(value)
			if (arr.length === 1) {
				arr = []
			} else {
				arr.splice(index, 1)
			};
		}
		inputRef.current = arr
	};

	const handlePageChange = (type) => {
		setData({
			...data,
			feature: inputRef.current
		})
		if (type === 'previous') {
			setPage(page - 1)
			setX(-1000)
		}

		if (type === 'next') {
			setPage(page + 1)
			setX(1000)

		}
	};
	return (
		<motion.div                            //updated the div tag
			initial={{ x: x }}
			transition={{ duration: 0.5 }}
			animate={{ x: 0 }}
		>
			<Stack h='100vh' display='flex' flexDir='column' justifyContent='center' alignItems='center'>
				<Box bg='white' minW={{ base: 'full', lg: '70%' }} position='relative' p={20} display='flex' flexDir='column' justifyContent='center' alignItems='center' gap={5}>
					<Progress value={(page / 4) * 100} position='absolute' top={0} right={0} left={0} />
					<Heading>Features : </Heading>
					<CheckboxGroup>
						<Stack>
							{['courses'].map(
								(offers, index) => (
									<Checkbox
										key={index}
										value={offers}
										onChange={(e) => handleCheckboxChange(e.target.checked, e.target.value)}
									>
										{offers}
									</Checkbox>
								)
							)}
						</Stack>
					</CheckboxGroup>
					<Flex gap={5} position='absolute' bottom={0} right={0} m={5}>
						<Button onClick={() => handlePageChange('previous')} colorScheme='red'>Previous</Button>
						<Button onClick={() => handlePageChange('next')} colorScheme='blue'>Next</Button>
					</Flex>
				</Box>

			</Stack>

		</motion.div>
	)
}

export default Features