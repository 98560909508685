import { Heading } from "@chakra-ui/react";
import moment from "moment";


const ReportTemplate = (props) => {
	const data = props.data;
	const date = moment().format('llll');
	return (
		<>
			<div
				style={{  width: "1000", padding: 5, backgroundColor: "white" }}
			>
				{/* <p>{JSON.stringify(data)}</p> */}
				<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", borderWidth: "1px" }}>
					<div style={{display:'flex'}}>
						<img
							src="https://upload.wikimedia.org/wikipedia/commons/9/92/New_Logo_JNE.png"
							alt="jne"
							width={50}
							style={{
								objectFit: "contain"
							}}
						></img>
						<Heading mx={20} fontSize={20} fontWeight="800">{data?.selectedCourier?.service_display}</Heading>
					</div>
					<img
						src="https://firebasestorage.googleapis.com/v0/b/deoapp-indonesia.appspot.com/o/yfAyafzZBzOBevshAE0crucAHNx1%2Fcourses%2FScreenshot%202023-07-28%20at%2016.23.19_800x800.png?alt=media&token=ef4c5905-e0be-4b77-8bdc-be586b845d4e"
						alt="jne" width="50"
						style={{
							objectFit: "contain"
						}}
					></img>
				</div>
				<div style={{fontSize: 10, display: "flex", flexDirection: "row", width: "100%" }}>
					<div style={{ width: "50%", borderWidth: "1px" }}>
						<p>penerima:</p>
						<p style={{ fontWeight: "bold" }}>{data?.name} ({data?.phoneNumber})</p>
						<p>{data?.fullAddress}</p>
						<h1 style={{fontWeight :"bold" ,fontSize:20}}>{data?.selectedDestination?.City_Code}</h1>
					</div>
					<div style={{ width: "50%", borderWidth: "1px" }}>
						<p>pengirim</p>
						<p style={{ fontWeight: "bold" }}>DBrand (087806848932)</p>
						<p>Jl. RS. Fatmawati Raya No.30 H, RT.1/RW.10, Cilandak Bar., Kec. Cilandak, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12430</p>
					</div>
				</div>
				<div style={{fontSize: 10, display: "flex", flexDirection: "row", width: "100%", borderWidth: "1px" }}>
					<div style={{ width: "50%", borderWidth: "1px" }}>
						<p style={{ fontWeight: "bold" }}>Detail goods : Buku Bisa Bikin Brand 1 - Subiakto Priosoedarsono</p>
						<p>Quantity : 1</p>
						<p>With Insurance : NO</p>
						<p>Estimasi Ongkir : {data?.selectedCourier?.price}</p>
						<p style={{ fontWeight: "bold" }}>ORDER ID : {data?.id}</p>
						<p>Packing Kayu : NO</p>
						<p>Print date : {date}</p>
					</div>
					<div style={{ display: "block", width: "50%", borderWidth: "1px" }}>
						<img src={`https://barcode.orcascan.com/?type=code128&data=${data?.requestCredential?.cnote_no}`}
							style={{ height: 100 }}
							alt="resi"></img>
						<p>AWB:<strong>{data?.requestCredential?.cnote_no}</strong></p>
					</div>
				</div>
			</div>
		</>
	)
};

export default ReportTemplate;