import React from 'react'
import LoginPage from '../Pages/Authentication/LoginPage'
import SigunUpPage from '../Pages/Authentication/SignUpPage'
import ForgotPasswordPage from '../Pages/Authentication/ForgotPasswordPage';
import RedirectElement from '../Pages/Authentication/RedirectElement';


const AuthenticationRouter = [
	{
		path: '/',
		element: <RedirectElement />,
	},
	{
		path: '/login',
		element: <LoginPage />,
	},
	{
		path: '/signup',
		element: <SigunUpPage />,
	},
	{
		path: '/forgotpass',
		element: <ForgotPasswordPage />,
	},

];

export default AuthenticationRouter