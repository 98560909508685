import {
    Button,
    Heading,
    HStack,
    Spacer,
    Stack,
    SimpleGrid,
    Divider,
    Center,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useGlobalState } from "../../Hooks/Contexts";
import { TransactionTable } from "../../Components/Tables/TransactionTable";
import { formatFrice } from "../../Utils/numberUtil";
import { getCollectionFirebase, getCollectionFirebaseV2 } from "../../Apis/firebaseApi";
import { collection, getCountFromServer, query, where } from "firebase/firestore";
import { db } from "../../Config/firebase";
import { useNavigate } from "react-router-dom";

function TransactionPage() {
    // ** HOOKS
    const globalState = useGlobalState();
    const navigate = useNavigate();
    // ** USESTATES
    const [dataTransaction, setDataTransaction] = useState([]);
    const [coursesCount, setCoursesCount] = useState(0);
    const [loadMore, setLoadmore] = useState(true)
    const [allTransaction, setAllTransaction] = useState()
    const [last, setLast] = useState();


    const getData = async () => {
        const conditions = [
            { field: "projectId", operator: "==", value: globalState?.currentProject?.id },
            { field: 'companyId', operator: '==', value: globalState?.currentCompany?.id },
            { field: 'module', operator: '==', value: 'lms' },
            // { field: 'paymentStatus', operator: '==', value: "PAID" }
        ];
        const sortBy = { field: 'createdAt', direction: 'desc' }; //createdAt, desc
        const limitValue = 10;
        try {
            const res = await getCollectionFirebase('orders', { conditions }, { sortBy }, { limitValue })
            setDataTransaction(res)
            const lastVisible = res[res?.length - 1].lastUpdated
            setLast(lastVisible)


        } catch (error) {
            console.log(error, 'ini error');
        }
    };

    const getCoursesCount = async () => {
        try {
            const coll = query(collection(db, "courses"), where("projectsId", "==", globalState?.currentProject?.id));
            const snapshot = await getCountFromServer(coll);
            setCoursesCount(snapshot.data()?.count);
        } catch (error) {
            console.log("error getCoursesCount ", error.message);
        };
    };

    const fetchNext = async () => {
        const conditions = [
            { field: "projectId", operator: "==", value: globalState?.currentProject?.id },
            { field: 'companyId', operator: '==', value: globalState?.currentCompany?.id },
            { field: 'module', operator: '==', value: 'lms' },
        ];
        const sortBy = { field: 'createdAt', direction: 'desc' };
        //createdAt, desc
        const limitValue = 10;

        try {
            const next = await getCollectionFirebaseV2('orders', { conditions }, { sortBy }, { limitValue }, { afterData: last })
            setDataTransaction([...dataTransaction, ...next])
            const lastVisible = next[next?.length - 1].lastUpdated
            setLast(lastVisible)
        } catch (error) {
            console.log(error)
        };
    };

    useEffect(() => {
        getData();
        getCoursesCount();
        return () => {
        }
    }, [globalState.currentProject])

    return (
        <Stack>
            <HStack p={[1, 1, 5]}>
                <Heading textTransform={"capitalize"} size="lg">
                    Dashboard
                </Heading>
                <Spacer />
                <HStack justifyContent="end" gap={5}>

                </HStack>
            </HStack>

            <Stack p={[1, 1, 5]} spacing={10}>
                <SimpleGrid columns={[1, 2, 2]} gap={5}>

                    {/* <Stack
                        cursor="pointer"
                        alignItems={'center'}
                        justifyContent='center'
                        _hover={{
                            transform: 'scale(1.04)',
                            transition: "60ms linear",
                        }}
                        onClick={() => console.log('income')}
                    >
                        <Stack bgColor={'white'} w={'100%'} h='100px' py={2} borderRadius='2xl' shadow={'md'}>
                            <Heading size={"md"} textAlign="center" >
                                Income Total
                            </Heading>

                            <Divider />

                            <Stack alignItems={'center'} justifyContent='center' h={'80%'} >
                                <Heading textAlign={'center'} size={'md'} color='green.500'>  Rp. {formatFrice(0)}</Heading>
                            </Stack>

                        </Stack>
                    </Stack> */}



                    <Stack
                        cursor='pointer'
                        onClick={() => navigate('courses')}
                        alignItems={'center'}
                        justifyContent='center'
                        _hover={{
                            transform: 'scale(1.04)',
                            transition: "60ms linear",
                        }}
                    >
                        <Stack bgColor={'white'} w={'100%'} h='100px' py={2} borderRadius='2xl' shadow={'md'}>
                            <Heading size={"md"} textAlign="center" pt={2}>
                                Total Course
                            </Heading>

                            <Divider />

                            <Stack
                                alignItems={'center'}
                                justifyContent='center'
                                h={'80%'}
                            >
                                <Heading textAlign={'center'} size={'md'} color='red.500'>{coursesCount}</Heading>
                            </Stack>

                        </Stack>
                    </Stack>
                </SimpleGrid>
                {dataTransaction?.length > 0 && <>
                    <TransactionTable data={dataTransaction} />
                    <Center w={'full'} justify={'space-between'}>
                        <Button onClick={() => fetchNext()}>Load More</Button>
                    </Center>
                </>}


            </Stack>
        </Stack>
    );
}

export default TransactionPage;
