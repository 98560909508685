import {
	Box,
	Button,
	Checkbox,
	Container,
	Divider,
	FormControl,
	FormLabel,
	Heading,
	HStack,
	Image,
	Input,
	Stack,
	Text,
	useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { getCollectionFirebase, getCollectionWhereFirebase, loginUser } from '../../Apis/firebaseApi'
import { Logo } from '../../Components/Logo'
import { useGlobalDispatch, useGlobalState } from '../../Hooks/Contexts'
import { useNavigate } from 'react-router-dom'
import logoDeoapp from '../../Assets/Logo/64174d501639e03e287e2160.png'
import { auth } from '../../Config/firebase'
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'

// import { OAuthButtonGroup } from './OAuthButtonGroup'
// import { PasswordField } from './PasswordField'

function LoginPage() {
	// ** Hooks
	const dispatch = useGlobalDispatch();
	const toast = useToast();
	const navigate = useNavigate();

	// ** States
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);


	// ** Handle
	const handleLogin = async () => {
		setLoading(true)
		const result = await loginUser(data.email, data.password, dispatch)

		signInWithEmailAndPassword(auth, data.email, data.password).then((userCredential) => {
			const conditions = [
				{ field: "users", operator: "array-contains", value: userCredential.user.uid },
			];

			// *****Save to localsorage to be read in reducers
			localStorage.setItem('user', JSON.stringify(userCredential.user))
		
			// *****Get projects
			getCollectionFirebase('projects', { conditions }, { sortBy: null }, { limitValue: null })
				.then((result) => {
					if (
						result?.length > 0
						// result?.filter(x => x.modules?.includes('lms'))?.length > 0
					) {
						navigate('/')
					} else {
						signOut(auth)
						toast({
							title: 'Oops',
							description: "Seems that you don't have any projects, please contact us to make projects",
							isClosable: true,
							duration: 9000,
							status: 'error'
						})
					}
				})
				.catch(error => {
					toast({
						title: 'Error occurred',
						description: error.message,
						isClosable: true,
						duration: 9000,
						status: 'error'
					})
				})
		}).catch((error) => {
			if (error.message?.includes('auth/wrong-password')) {
				toast({
					title: 'Wrong Password',
					description: 'Please type in correct password, or your account will be disabled',
					status: 'error',
					isClosable: 9000,
				})
			} else {
				toast({
					title: 'Authentication Failed',
					description: error.message,
					status: 'error',
					isClosable: 9000,
				})
			}
		}).finally(() => {
			setLoading(false)
		})
		// .then((loginResponse) => {
		// 	console.log(loginResponse, "response")
	}

	const handleEnter = e => {
		if (e.key === 'Enter') handleLogin()
	}



	return (
		<Box
			w={'100%'}
			bgColor={'#f5faff'}
		>

			<Container
				maxW="lg"
				py={{
					base: '12',
					md: '24',
				}}
				px={{
					base: '0',
					sm: '8',
				}}

			>
				<Stack spacing="8">
					<Stack spacing="6" align={'center'}>
						<Image w={'50%'} src={logoDeoapp} />
						<Stack
							spacing={{
								base: '2',
								md: '3',
							}}
							textAlign="center"
						>
							<Heading
								size={{
									base: 'xs',
									md: 'sm',
								}}
							>
								Log in to your account
							</Heading>

							<HStack spacing="1" justify="center">
								<Text color="muted">Don't have an account?</Text>
								<Button variant="link" colorScheme="blue" onClick={() => navigate('/signup')}>
									Sign up
								</Button>
							</HStack>
						</Stack>
					</Stack>
					<Box
						py={{
							base: '0',
							sm: '8',
						}}
						px={{
							base: '4',
							sm: '10',
						}}
						bg={{
							base: 'transparent',
							sm: 'bg-surface',
						}}
						boxShadow={{
							base: 'none',
							sm: 'md',
						}}
						borderRadius={{
							base: 'none',
							sm: 'xl',
						}}
						bgColor={'white'}
					>
						<Stack spacing="6">
							<Stack spacing="5">
								<FormControl>
									<FormLabel htmlFor="email">Email</FormLabel>
									<Input id="email" type="email" onChange={(e) => setData({ ...data, email: e.target.value })} />
								</FormControl>
								<FormControl>
									<FormLabel htmlFor="password">Password</FormLabel>
									<Input onKeyDown={handleEnter} id="password" type="password" onChange={(e) => setData({ ...data, password: e.target.value })} />
								</FormControl>
							</Stack>
							<HStack justify="space-between">
								<Checkbox defaultChecked>Remember me</Checkbox>
								<Button variant="link" colorScheme="blue" size="sm" onClick={() => navigate('/forgotpass')}>
									Forgot password?
								</Button>
							</HStack>
							<Stack spacing="6">
								<Button isLoading={loading} disabled={loading} variant="primary" onClick={() => handleLogin()} loadingText="Signing in...">Sign in</Button>
								<HStack>
									<Divider />
									<Text fontSize="sm" whiteSpace="nowrap" color="muted">
										or continue with
									</Text>
									<Divider />
								</HStack>
								{/* <OAuthButtonGroup /> */}
							</Stack>
						</Stack>
					</Box>
				</Stack>
			</Container>
		</Box>
	)
}






export default LoginPage