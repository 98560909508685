import { Box, Button, Flex, HStack, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Progress, Spinner, Stack, Text, useDisclosure } from '@chakra-ui/react'
import React, { useRef, useState } from 'react';
import { motion } from "framer-motion";
import { addDocumentFirebase, arrayUnionFirebase, getSingleDocumentFirebase, setDocumentFirebase } from '../../../Apis/firebaseApi';
import { useGlobalDispatch, useGlobalState } from '../../../Hooks/Contexts';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../../Config/firebase';
import Swal from 'sweetalert2';

const Domains = ({ page, setPage, data, setData, x, setX }) => {
	const inputRef = useRef();
	const [loading, setLoading] = useState(false);
	const { onOpen, isOpen, onClose } = useDisclosure();
	const navigate = useNavigate();
	const globalState = useGlobalState();
	const dispatch = useGlobalDispatch();

	const handleChange = value => {
		inputRef.current = value
	};

	const handlePageChange = (type) => {
		setData({
			...data,
			domain: inputRef.current
		})
		if (type === 'previous') {
			setPage(page - 1)
			setX(-1000)
		};
	};

	const saveToProjectsAndPages = async () => {
		setLoading(true)
		const docId = await addDocumentFirebase('projects', {
			name: data.projectName,
			owner: globalState.uid,
			team: []
		});

		if (docId) {
			console.log(`project made with id : ${docId}`)

			setDocumentFirebase(
				'pages',
				docId,
				{
					domains: [
						data.domain === undefined ? inputRef.current : data.domain
					],
					features: [
						...data.feature
					]
				},
				docId
			).then(()=>{
				arrayUnionFirebase('users', globalState.uid, 'projects', [docId]).then(() => {
					onOpen();
					getSingleDocumentFirebase('users', globalState.uid)
						.then(data => {
							dispatch({
								type: "LOGIN_SUCCESS",
								payload: {
									user: data,
								},
							});
							
						})
				})
			})
			arrayUnionFirebase('users', globalState.uid, 'projects', [docId]).then(() => {
				getSingleDocumentFirebase('users', globalState.uid)
					.then(data => {
						dispatch({
							type: "LOGIN_SUCCESS",
							payload: {
								user: data,
							},
						});
					})
			})
			Swal.fire(
				'Success',
				`Success creating project with id : ${docId}`,
				'success'
			  ).then(()=>{
				navigate('/')
			  })
		} else {
			alert(docId.message);
		};
	}

	const handleFinish = async () => {
		if (data.domain === null || data.domain?.length === 0 || !inputRef.current) {
			alert('Please input project name!');
		} else {
			setData({
				...data,
				domain: inputRef.current
			});

			await saveToProjectsAndPages();
		};
	};


	return (
		<motion.div                            //updated the div tag
			initial={{ x: x }}
			transition={{ duration: 0.5 }}
			animate={{ x: 0 }}
		>
			<Stack h='100vh' display='flex' flexDir='column' justifyContent='center' alignItems='center'>
				<Box bg='white' minW={{ base: 'full', lg: '70%' }} position='relative' p={20} display='flex' flexDir='column' justifyContent='center' alignItems='center' gap={5}>

					<Progress value={(page / 4) * 100} position='absolute' top={0} right={0} left={0} />
					<Heading onClick={()=>onOpen()}>Domain</Heading>

					<Input
						onChange={e => handleChange(e.target.value)}
					/>

					<Flex gap={5} position='absolute' bottom={0} right={0} m={5}>
						<Button onClick={() => handlePageChange('previous')} colorScheme='red'>Previous</Button>
						<Button disabled={loading} onClick={() => handleFinish()} colorScheme='blue'>
							<HStack>
								<Text>Finish</Text>
								{loading ? <Spinner mx={5} /> : null}
							</HStack>
						</Button>
					</Flex>

				</Box>
			</Stack>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Success</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>Project successfully saved!</Text>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme='blue' mr={3} onClick={() => navigate('/')}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</motion.div>

	)
}

export default Domains