import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
	Box,
	Button,
	Center,
	Flex,
	Heading,
	HStack,
	Image,
	Input,
	Radio,
	RadioGroup,
	Spacer,
	Stack,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Tab,
	Text,
	Container,
	Select,
	InputGroup,
	InputRightElement,
	Progress,
	useToast,
} from "@chakra-ui/react";
import parse from 'html-react-parser';
import {
	FiDelete,
	FiDownload,
	FiEyeOff,
	FiVideo,
	FiVolume2,
} from "react-icons/fi";
import ReactQuill from "react-quill";
import ReactPlayer from "react-player";
import "react-quill/dist/quill.snow.css";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
	arrayUnionFirebase,
	deleteDocumentFirebase,
	deleteFileFirebase,
	getCollectionFirebase,
	getSingleDocumentFirebase,
	setDocumentFirebase,
	updateDocumentFirebase,
	uploadFileFirebase,
} from "../../Apis/firebaseApi";
import { auth, db, storage } from "../../Config/firebase";
import { UploadBlob } from "../../Utils/Upload";
import { useDropzone } from 'react-dropzone';
import { deleteObject, ref } from "firebase/storage";
import { useGlobalState } from "../../Hooks/Contexts";
import { arrayRemove, doc, updateDoc } from "firebase/firestore";
import Swal from "sweetalert2";
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from "../../Constants/Constants";

function EditLesson() {
	const navigate = useNavigate();
	const params = useParams();
	const uid = auth.currentUser.uid;
	const { currentProject } = useGlobalState();

	const [lesson, setLesson] = useState(null);
	const [progress, setProgress] = useState(0);
	const [defaultIndex, setDefaultIndex] = useState(0);
	const [isUploading, setIsUploading] = useState(false);
	const [type, setType] = useState("youtube");
	const [course, setCourse] = useState(null)
	const toast = useToast();

	const inputRef = useRef();
	const videoRef = useRef();

	//================================================================================================
	//=======================================HANDLES==================================================
	//================================================================================================

	const handleSave = () => {
		if (isUploading) { }
		updateDocumentFirebase(`courses/${params.id_course}/lessons`, params.id_lesson, lesson).then(() => {
			navigate(-1)
		}).catch(error => {
			toast({
				title: "error",
				description: error.message,
				isClosable: true,
				status: "error",
				duration: 9000
			})
		})
	};

	const handleDeleteMedia = async () => {
		// Create a reference to the file to delete

		if (lesson.type) {

		}

		const confirmToDelete = window.confirm("Are you sure to delete the media? This process cannot be undone");

		if (confirmToDelete) {



		}


		const desertRef = ref(storage, lesson.mediaPath);

		// // Delete the file
		deleteObject(desertRef).then(() => {
			// File deleted successfully
			console.log('success deleting from storage')
		}).then(() => { })
			.catch(error => console.log(error.message, "error when deleting storage"))

		//deleting from firestore
		setDocumentFirebase(
			`courses/${params.id_course}/lessons/`,
			params.id_lesson,
			{
				...lesson,
				media: null,
				mediaPath: '',
				mediaType: '',
				mediaTitle: ''
			},
			currentProject.id)

			.then((res) => {
				console.log(res)

				//deleting from state
				setLesson({
					...lesson,
					media: null,
					mediaPath: '',
					mediaType: '',
					mediaTitle: ''
				})
			}).catch(e => console.log(e.message))
	};

	const getLessons = async () => {
		const conditions = [
			{ field: "createdBy", operator: "==", value: uid },
		];
		const sortBy = { field: "createdAt", direction: "asc" };
		const res = await getCollectionFirebase(
			`courses/${params.id_course}/lessons`,
			conditions,
			sortBy,
			{ limitValue: null }
		);
		setLesson(res.find((e) => e.id === params.id_lesson));
	};

	const handleUploadThumbnail = async (file) => {
		setIsUploading(true);
		UploadBlob(file, uid, "lessons", file.name, setIsUploading).then(
			(uploadedImg) => {
				console.log(uploadedImg.replace(/(\.[^.\/\\]+)$/i, '_800x800$1'), "this is data result");
				setLesson({
					...lesson,
					thumbnail: uploadedImg.url.replace(/(\.[^.\/\\]+)$/i, '_800x800$1'),
				});
				setIsUploading(false);
				arrayUnionFirebase(
					`courses/${params.id_course}/lessons`,
					`${params.id_lesson}`,
					'thumbnail',
					[uploadedImg.url.replace(/(\.[^.\/\\]+)$/i, '_800x800$1')]
				);
				updateDocumentFirebase(
					`courses/${params.id_course}/lessons`,
					`${params.id_lesson}`,
					{
						thumbnail: uploadedImg.url.replace(/(\.[^.\/\\]+)$/i, '_800x800$1'),
					}
				).then(() => {
					getLessons()
				})
			}
		);

	};

	const getCourses = async () => {
		const res = await getSingleDocumentFirebase('courses', `${params.id_course}`)
		setCourse(res)
	}

	const handleDelete = async (type) => {
		if (type === "image") {
			const splitArr = lesson?.thumbnail[0].split("?");
			const secondSplit = splitArr[0].split("%2F");
			deleteFileFirebase(uid, "lessons", secondSplit[2]).then(() => {
				updateDoc(doc(db, `courses/${params.id_course}/lessons`, `${params.id_lesson}`), {
					thumbnail: []
				}).then(() => {
					getLessons()
				})
			})

		} else if (type === 'lesson') {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.isConfirmed) {



					let newFind = course?.lessons?.find(item => item?.id === params.id_lesson || item?.title === lesson?.title)
					const splitArr = lesson?.thumbnail && lesson?.thumbnail[0]?.split("?");
					const secondSplit = splitArr && splitArr[0]?.split("%2F");

					if (secondSplit && lesson?.media) {
						handleDeleteMedia()
						deleteFileFirebase(uid, "lessons", secondSplit[2]).then(() => {
							updateDoc(doc(db, `courses/${params.id_course}/lessons`, `${params.id_lesson}`), {
								thumbnail: []
							}).then(() => {
								updateDoc(doc(db, `courses`, `${params.id_course}`), {
									lessons: arrayRemove(newFind)
								}).then(() => {
									deleteDocumentFirebase(`courses/${params.id_course}/lessons`, `${params.id_lesson}`)
									navigate(-1)
								});
							})

							Swal.fire(
								'Deleted!',
								'Your file has been deleted.',
								'success'
							)
						})
					} else if (secondSplit) {
						deleteFileFirebase(uid, "lessons", secondSplit[2]).then(() => {
							updateDoc(doc(db, `courses`, `${params.id_course}`), {
								lessons: arrayRemove(newFind)
							}).then(() => {
								deleteDocumentFirebase(`courses/${params.id_course}/lessons`, `${params.id_lesson}`).then(() => {
									navigate(-1)
								})
							});
						})

					} else if (lesson?.media) {
						handleDeleteMedia().then(() => {
							updateDoc(doc(db, `courses`, `${params.id_course}`), {
								lessons: arrayRemove(newFind)
							}).then(() => {
								deleteDocumentFirebase(`courses/${params.id_course}/lessons`, `${params.id_lesson}`)
								navigate(-1)
							});
						})

					} else {
						updateDoc(doc(db, `courses`, `${params.id_course}`), {
							lessons: arrayRemove(newFind)
						}).then(() => {
							deleteDocumentFirebase(`courses/${params.id_course}/lessons`, `${params.id_lesson}`)
							navigate(-1)
						});
					}
				}
			})

		}
	};

	const handleUploadVideoToStorage = async (file) => {
		const result = await uploadFileFirebase(file, null, setProgress, null, { type: null })
		if (result) {
			setLesson({
				...lesson,
				mediaType: type,
				mediaPath: result.path,
				media: result.image_url,
				mediaTitle: file?.name
			})
			console.log({
				...lesson,
				mediaType: type,
				mediaPath: result.path,
				media: result.image_url,
				mediaTitle: file?.name
			}, 'uploading video')
		}
	};

	const handleRadioButton = (checked, value) => {
		if (checked && value === 'draft') {
			setLesson({
				...lesson,
				status: value
			})
		}
	};


	//================================================================================================
	//=======================================COMPONENTS===============================================
	//================================================================================================
	function MyDropzone() {


		const onDrop = useCallback(async acceptedFiles => {
			// Do something with the files
			await handleUploadVideoToStorage(acceptedFiles[0])
		}, [])

		const {
			getRootProps,
			getInputProps,
			isFocused,
			isDragAccept,
			isDragReject
		} = useDropzone({ accept: { video: ['.avi', '.mp4', '.mpeg', '.ogv', '.webm', '.3gp', '.mov', '.mkv'] }, onDrop });

		const style = useMemo(() => ({
			...baseStyle,
			...(isFocused ? focusedStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {})
		}), [
			isFocused,
			isDragAccept,
			isDragReject
		]);



		return (
			<div className="container">
				<div {...getRootProps({ style })}>
					<input {...getInputProps()} />
					<p>Drag drop you video course here, or click to select files</p>
					{progress !== 0 && progress !== 100 ? <p>progress is {progress?.toFixed(2)}%</p> : <></>}
				</div>
			</div>

		);
	};

	const MediaType = () => (
		<Tabs isFitted variant="soft-rounded" defaultIndex={defaultIndex} onChange={index => setDefaultIndex(index)}>
			<TabList>
				<Tab>
					<HStack>
						<FiVideo />
						<Text>Video</Text>
					</HStack>
				</Tab>
				<Tab>
					<HStack>
						<FiVolume2 />
						<Text>Audio</Text>
					</HStack>
				</Tab>
			</TabList>

			<TabPanels>
				<TabPanel>
					<Container
						borderRadius="md"
						p="5"
					>
						{/* <RadioGroup onChange={(e) => setType(e)}> */}
						<Text color="gray"> Please Choose Media Source (Youtube link or directly upload video from your computer) :</Text>
						<Flex my={5} gap={5} flexDirection='row' w='full' justifyContent='center' alignItems='center'>
							<Box
								bg='rgba(174,182,183, 0.1)'
								p={3}
								borderRadius={5}
								cursor='pointer'
								fontWeight={type === "youtube" ? "bold" : ""}
								color={type === "youtube" ? "blue" : "#2d2d2d"}
								onClick={() => setType("youtube")}>
								Youtube
							</Box>
							<Box
								bg='rgba(174,182,183, 0.1)'
								p={3}
								borderRadius={5}
								cursor='pointer'
								fontWeight={type === "upload" ? "bold" : ""}
								color={type === "upload" ? "blue" : "#2d2d2d"}
								onClick={() => setType("upload")}>
								Upload
							</Box>
						</Flex>
						{/* </RadioGroup> */}
						{type === "upload" ? (
							<>
								<MyDropzone />
								<Text fontWeight="bold" fontSize={12}>Please Note! Accepted file types are : </Text>
								<Text color="red" fontWeight="bold" fontSize={12}>'.avi', '.mp4', '.mpeg', '.ogv', '.webm', '.3gp', '.mov', '.mkv'</Text>
							</>
						) : type === "youtube" ? (
							<>
								<InputGroup size="md">
									<Input
										shadow='sm'
										bg='white' placeholder="Input link youtube"
										onChange={e => {
											videoRef.current = e.target.value
										}} />
									<InputRightElement w="fit-content">
										<Button onClick={() => setLesson({
											...lesson,
											media: videoRef.current
										})}>Submit</Button>
									</InputRightElement>
								</InputGroup>
							</>
						) : null}
						{progress === 0 || progress === 100 || isNaN(progress) ?
							null
							:
							<>
								<Progress value={progress} />
								<Text>
									{progress?.toFixed(2)} %
								</Text>
							</>
						}
					</Container>
				</TabPanel>
				<TabPanel>
					<Container
						borderRadius="md"
						p="5"
						border="1px"
						borderColor="gray"
						borderStyle="dotted"
					>
						<Center>
							<FiVolume2 width="25px" />
						</Center>
						<Center>
							<Button>Upload Audio</Button>
						</Center>
					</Container>
				</TabPanel>
			</TabPanels>
		</Tabs>
	);

	useEffect(() => {
		getCourses()
		getLessons();
	}, []);


	return (
		<>
			<Box>
				<HStack>
					<HStack
						color="#2c698d"
						fontSize="14px"
						onClick={() => navigate(-1)}
						cursor='pointer'
					>
						<ChevronLeftIcon />
						<Text>Back</Text>
					</HStack>
					<Heading>
						{lesson?.title ? lesson.title : <></>}
					</Heading>
					<Spacer />
					<Button
						colorScheme="red"
						onClick={() => handleDelete('lesson')}
					>
						Delete
					</Button>
					<Button colorScheme="green" onClick={handleSave}>Save</Button>
				</HStack>
				<Flex mt={10}>
					<Box width="70%" bg="white">
						<Box borderRadius="md" shadow="base" p="2" m="1">
							<Text fontWeight="bold" m="1">Lesson Title :</Text>
							<Input
								shadow='sm'
								bg='white'
								type="text"
								onChange={(e) =>
									setLesson({
										...lesson,
										title: e.target.value,
									})
								}
								value={lesson?.title}
							/>

							<Text fontWeight="bold" m="1">Section :</Text>
							<Select
								bg='white'
								onChange={(e) =>
									setLesson({
										...lesson,
										section: e.target.value,
									})
								}
							>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
							</Select>

							<Text fontWeight="bold" m="1">Media :</Text>
							<Box
								p="5"
								border="1px"
								borderColor="gray"
								borderStyle="dotted">
								{lesson?.media ? (
									<>
										<ReactPlayer
											width="full"
											controls={true}
											url={lesson?.media}
										/>
										<Button my={5} colorScheme='red' onClick={handleDeleteMedia}>Delete Media</Button>
									</>

								) : (
									<MediaType />
								)}
							</Box>

							<Box
								borderRadius="md"
								p="2"
							// mr="2"
							// ml="2"
							>
								<Heading size='sm'>Description :</Heading>

								{lesson?.description ?
									<>
										{parse(lesson?.description ? lesson?.description : null)}
									</> : null}

								<ReactQuill
									ref={inputRef}
									theme="snow"
									style={{
										backgroundColor: 'white',
										boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.11), 0 3px 10px 0 rgba(0, 0, 0, 0.1)",
									}}
									onChange={e => setLesson({
										...lesson,
										description: e
									})}
								/>
							</Box>

							{/* <HStack m="2">
								<Text>Files</Text>
								<Spacer />
								<Button size="xs" colorScheme="green">
									Add Files
								</Button>
							</HStack> */}
							<Box
								borderRadius="md"
								border="1px"
								borderColor="gray.50"
								pl="2"
								pr="2"
								mr="2"
								ml="2"
							>
								{lesson?.download ? (
									<Box
										borderBottom="1px"
										borderColor="gray.50"
									>
										<HStack>
											<Text>
												{/* {getFileName(
													data.download
												)} */}
											</Text>
											<Spacer />
											<a
												href={
													lesson.download
												}
												download
												target="_blank"
												rel="noreferrer"
											>
												<FiDownload />
											</a>
											<FiDelete />
										</HStack>
									</Box>
								) : (
									<Box
										borderBottom="1px"
										borderColor="gray.50"
									></Box>
								)}
							</Box>
						</Box>
					</Box>

					<Box width="30%">
						<Box borderRadius="md" shadow="base" p="2" m="1" bg="white">
							<Heading fontSize="md">Status</Heading>
							<RadioGroup>
								<Stack direction="column">
									<RadioGroup
										onChange={(e) =>
											setLesson({
												...lesson,
												status: e,
											})
										}
									>
										<Stack>
											<Radio checked={lesson?.status === 'draft'} value="draft" onChange={e => handleRadioButton(e.target.checked, e.target.value)}>
												Draft
											</Radio>
											<Radio checked={lesson?.status === 'published'} value="published" onChange={e => handleRadioButton(e.target.checked, e.target.value)}>
												Published
											</Radio>
										</Stack>
									</RadioGroup>
								</Stack>
							</RadioGroup>
						</Box>

						{/* <Box borderRadius="md" shadow="base" p="2" m="1">
							<Heading fontSize="md">Thumbnail</Heading>
							<HStack>

								{lesson?.thumbnail?.length > 0 ? (
									<>
										<Image
											width="100px"
											height="50px"
											objectFit="cover"
											src={lesson?.thumbnail[0] || lesson?.thumbnail}
											alt={lesson?.title}
										/>
										<Button
											colorScheme="red"
											onClick={() =>
												handleDelete(
													"image"
												)
											}
										>
											Delete
										</Button>
									</>
								) : (
									<Input
										shadow='sm'
										bg='white'
										type="file"
										variant="ghost"
										onChange={(e) =>
											handleUploadThumbnail(
												e.target.files[0]
											)
										}
									/>
								)}
							</HStack>
						</Box> */}

						{/* <Box borderRadius="md" shadow="base" p="2" m="1">
							<Heading fontSize="md">Comment</Heading>
							<RadioGroup>
								<Stack direction="column">
									<RadioGroup
										onChange={(e) =>
											setLesson({
												...lesson,
												comment: e,
											})
										}
									>
										<Stack>
											<Radio value="visible">
												visible
											</Radio>
											<Radio value="hidden">
												hidden
											</Radio>
										</Stack>
									</RadioGroup>
								</Stack>
							</RadioGroup>
						</Box> */}
					</Box>
				</Flex>
			</Box>
		</>
	);
}
export default EditLesson;
