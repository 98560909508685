import React from 'react'
import { useGlobalState } from '../../Hooks/Contexts'
import { Navigate } from 'react-router-dom';

const RedirectElement = () => {
    const { isLoggedIn } = useGlobalState();

    if (isLoggedIn) return <Navigate to='/' />
    return <Navigate to='login' />
}

export default RedirectElement