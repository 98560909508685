import {
	Heading,
	Input,
	InputGroup,
	InputLeftElement,
	Select,
	Box,
	Button,
	Container,
	Stack,
	useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumbComponent from "../../Components/BreadCrumbs/BreadCrumbComponent";
import { FiPlus, FiSearch } from "react-icons/fi";
import BasicCardComponent from "../../Components/Cards/BasicCardComponent";
import { useNavigate } from "react-router-dom";
import { getCollectionFirebase, getCollectionFirebaseV2 } from "../../Apis/firebaseApi";
import { useGlobalState } from "../../Hooks/Contexts/contexts";

const Courses = () => {
	const [datas, setDatas] = useState([]);
	const [update, setUpdate] = useState(false);
	const { currentProject } = useGlobalState();
	const [last, setLast] = useState();

	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	});

	const data = [
		{ title: "Home", link: "/" },
		{ title: "Courses", link: "/courses" },
	];
	const course = [
		{ id: 1, title: "All Courses" },
		{ id: 2, title: "Course A" },
		{ id: 3, title: "Course B" },
	];
	const category = [
		{ id: 1, title: "All Categories" },
		{ id: 2, title: "Category A" },
		{ id: 3, title: "Category B" },
	];
	const author = [
		{ id: 1, title: "All Authors" },
		{ id: 2, title: "Author A" },
		{ id: 3, title: "Author B" },
	];

	const navigate = useNavigate();

	const getData = async () => {
		const conditions = [
			{ field: "projectsId", operator: "==", value: currentProject.id },
		];
		const sortBy = { field: "createdAt", direction: "asc" };
		const limitValue = 10;


		try {
			const res = await getCollectionFirebase(
				"courses",
				{ conditions },
				{ sortBy },
				{ limitValue }
			);
			setDatas(res);
			const lastVisible = res[res?.length - 1]?.createdAt;
			setLast(lastVisible);
		} catch (error) {
			console.log(error, "ini error");
		}
	};

	const fetchNext = async () => {
		const conditions = [
			{ field: "projectsId", operator: "==", value: currentProject?.id },
		];
		const sortBy = { field: 'createdAt', direction: 'asc' };
		//createdAt, desc
		const limitValue = 10;

		try {
			const nextData = await getCollectionFirebaseV2('courses', { conditions }, { sortBy }, { limitValue }, { afterData: last });
			setDatas([...datas, ...nextData])
			const lastVisible = nextData[nextData?.length - 1]?.createdAt
			setLast(lastVisible)
		} catch (error) {
			console.log(error)
		};
	};


	useEffect(() => {
		getData();

	}, [currentProject?.id]);

	return (
		<>
			<Heading>Courses</Heading>
			<BreadCrumbComponent data={data} />
			<Container
				py={{
					base: "4",
					md: "8",
				}}
				px={{
					base: "0",
					md: 8,
				}}
				maxW="7xl"
			>
				<Box
					bg="bg-surface"
					boxShadow={{
						base: "none",
						md: "sm",
					}}
					borderRadius={{
						base: "none",
						md: "lg",
					}}
				>
					<Stack spacing="5">
						<Box
							px={{
								base: "4",
								md: "6",
							}}
							pt="5"
						>
							<Stack
								direction={{
									base: "column",
									md: "row",
								}}
								justify="space-between"
							>
								<InputGroup w="fit-content">
									<InputLeftElement
										pointerEvents="none"
										children={
											<FiSearch color="gray.300" />
										}
									/>
									<Input
										type="search-course"
										placeholder="Search"
									/>
								</InputGroup>
								<Select w="fit-content">
									{course?.map((item, id) => (
										<option key={id}>
											{item.title}
										</option>
									))}
								</Select>
								<Select w="fit-content">
									{category?.map((item, id) => (
										<option key={id}>
											{item.title}
										</option>
									))}
								</Select>
								<Select w="fit-content">
									{author?.map((item, id) => (
										<option key={id}>
											{item.title}
										</option>
									))}
								</Select>
								<Button
									leftIcon={<FiPlus />}
									colorScheme="green"
									onClick={() =>
										navigate("/courses/create")
									}
								>
									Add Course
								</Button>
							</Stack>
						</Box>
						<Box>
							{datas?.map((item, i) => (
								<BasicCardComponent
									key={i}
									data={item}
									update={update}
									setUpdate={setUpdate}
									getData={getData}
									setDatas={setDatas}
									datas={datas}
								/>
							))}
						</Box>
						<Box
							px={{
								base: "4",
								md: "6",
							}}
							pb="5"
						>
							{/* <HStack spacing="3" justify="space-between">
								{!isMobile && (
									<Text color="muted" fontSize="sm">
										Showing 0 of {datas?.length} results
									</Text>
								)}
								<ButtonGroup
									spacing="3"
									justifyContent="space-between"
									width={{
										base: "full",
										md: "auto",
									}}
									variant="secondary"
								>
									<Button>Previous</Button>
									<Button>Next</Button>
								</ButtonGroup>
							</HStack> */}
							<Button onClick={fetchNext}>Next</Button>
						</Box>
					</Stack>
				</Box>
			</Container>
		</>
	);
};

export default Courses;
