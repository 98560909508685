import { createUserWithEmailAndPassword } from 'firebase/auth';
import React from 'react'
import { auth, db } from '../../Config/firebase';
import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	HStack,
	InputRightElement,
	Stack,
	Button,
	Heading,
	Text,
	useColorModeValue,
	Link,
  Image,
  } from '@chakra-ui/react';
  import { useState } from 'react';
  import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import logoDeoapp from '../../Assets/Logo/64174d501639e03e287e2160.png'
import { addDoc, doc, setDoc } from 'firebase/firestore';

function SigunUpPage() {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState ('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
	const navigate = useNavigate()
	const handleSignUp = () => {
		createUserWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				const user = userCredential.user;
        setDoc(doc(db, "users", user.uid), {
          email: email,
          name: firstName + lastName,
          owner : [],
          projects: [],
          users:[],
          phone,
          updatedBy : user.uid,
          updatedAt : new Date(),
        })
        navigate('/')
			})
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
        console.log(errorMessage)
			});
	}

	const [showPassword, setShowPassword] = useState(false);

	return (
		<>
		<Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
        <Image w={'50%'} src={logoDeoapp}/>
          <Text fontSize={'lg'} color={'gray.600'}>
            Register to this site
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <HStack>
              <Box>
                <FormControl id="firstName" isRequired>
                  <FormLabel>First Name</FormLabel>
                  <Input type="text" onChange={e=>setFirstName(e.target.value)} />
                </FormControl>
              </Box>
              <Box>
                <FormControl id="lastName">
                  <FormLabel>Last Name</FormLabel>
                  <Input type="text" onChange={e=>setLastName(e.target.value)}/>
                </FormControl>
              </Box>
            </HStack>
			<FormControl id="phone" isRequired>
              <FormLabel>Phone Number</FormLabel>
              <Input type="phone" onChange={e=>setPhone(e.target.value)}/>
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input type="email" onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input type={showPassword ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }>
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Stack spacing={10} pt={2}>
              <Button
                loadingText="Submitting"
                size="lg"
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                onClick={() => handleSignUp ()}>
                Sign up
              </Button>
            </Stack>
            <Stack pt={6}>
              <Text align={'center'}>
                Already a user? <Link color={'blue.400'} onClick={() => navigate('/login')}>Login</Link>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
		</>
	)
}

export default SigunUpPage