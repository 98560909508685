import {
	Box,
	Button,
	Center,
	Divider,
	Flex,
	// HStack,
	Image,
	// Progress,
	Select,
	Stack,
	// Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { FcCloseUpMode, FcReading, FcRules, FcSettings, FcMoneyTransfer } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { getCollectionFirebase, getSingleDocumentFirebase, logOutUser } from "../../Apis/firebaseApi";
import themeConfig from "../../Config/themeConfig";
import { useGlobalDispatch, useGlobalState } from "../../Hooks/Contexts";
import { NavButton } from "./NavButton";
import { UserProfile } from "./UserProfile";
import { auth } from "../../Config/firebase";
import { getCourses } from "../../Hooks/Contexts/actions";

// ** Theme Configuration

function SidebarComponentV2({ layout }) {
	const globalState = useGlobalState();
	const dispatch = useGlobalDispatch()
	const navigate = useNavigate()

	const user = auth.currentUser;

	const getCompanies = () => {
		const conditions = [{
			field: "users",
			operator: "array-contains",
			value: globalState?.uid
		}]
		getCollectionFirebase(
			'companies',
			{ conditions },
			{ sortBy: null },
			{ limitValue: null }
		).then((companies) => {
			dispatch({
				type: "COMPANIES",
				payload: companies
			})
			dispatch({
				type: "CURRENT_COMPANY",
				payload: { id: companies[0]?.id, name: companies[0]?.name }
			})
		}).catch(error => {
			console.log(error.message, "-----error getting companies, sidebar")
		}).finally(() => {
			// getProjects();
		})

		getSingleDocumentFirebase('users', globalState.uid).then((docData) => {
			// console.log(docData, "docData")
			dispatch({
				type: 'LOGIN_SUCCESS',
				payload: {
					user: docData
				}
			})
		})
	};

	const getProjects = () => {
		const conditions = [
			{
				field: "users",
				operator: "array-contains",
				value: globalState?.uid
			},
			{
				field: "companyId",
				operator: "==",
				value: globalState?.currentCompany?.id
			},
		];

		getCollectionFirebase(
			'projects',
			{ conditions },
			{ sortBy: null },
			{ limitValue: null }
		).then((projects) => {
			// const filteredProjects = projects?.filter(project => project?.modules?.includes('lms'));
			dispatch({
				type: "PROJECTS",
				payload: projects,
			});

			if (projects?.length > 0) {
				// if (filteredProjects?.length > 0) {
				dispatch({
					type: "CURRENT_PROJECTS",
					payload: {
						id: projects[0]?.id,
						name: projects[0]?.name
					}
				})
				getCourses(projects[0]?.id, dispatch);
			} else return;

		})

		getSingleDocumentFirebase('users', globalState.uid).then((docData) => {
			// console.log(docData, "docData")
			dispatch({
				type: 'LOGIN_SUCCESS',
				payload: {
					user: docData
				}
			})
		})
	};

	const handleLogout = async () => {
		navigate('/')
		logOutUser()
	};

	const handleChangeCompany = (id) => {
		dispatch({
			type: "CURRENT_COMPANY",
			payload: {
				id,
				name: globalState?.companies?.find(x => x.id === id)?.name
			}
		})
	};

	const handleChangeProject = (id) => {
		dispatch({
			type: "CURRENT_PROJECTS",
			payload: {
				id: id,
				name: globalState.companies.find(obj => obj.id === id)?.name
			}
		})
	};

	const isShowingDashboard = () => {
		const url = window.location.href
		if (
			url.includes('themes') ||
			url.includes('courses') ||
			url.includes('news') ||
			url.includes('qr') ||
			url.includes('settings') ||
			url.includes('login') ||
			url.includes('dashboard') || 
			url.includes('shipping') 
		) return false
		return true
	};


	useEffect(() => {
		getCompanies();
	}, []);

	useEffect(() => {
		getProjects();
	}, [globalState?.currentCompany]);


	if (layout.type === "vertical" || layout.type === "vertical-horizontal")
		return (
			<Box
				height="full"
				width={{
					md: "14rem",
					xl: "18rem",
				}}
				display={{
					base: "none",
					lg: "initial",
				}}
				overflowY="auto"
				//   borderRightWidth="1px"
				shadow={"base"}
				roundedBottomRight={"lg"}
				roundedTopRight={"lg"}
			>
				{/* <Box bg='red' position="sticky" overflowY="auto"> */}
				<Flex as="section" minH="100vh" boxShadow="sm">
					{/* <Flex
						flex="1"
						bg="bg-surface"
						boxShadow="sm"
						// maxW={{
						// 	base: "full",
						// 	sm: "xs",
						// }}
						py={{
							base: "6",
							sm: "8",
						}}
						px={{
							base: "4",
							sm: "6",
						}}
					> */}
					<Stack justify="space-between" spacing="1">
						<Stack
							spacing={{
								base: "5",
								sm: "6",
								lg: "3"
							}}
							shouldWrapChildren
						>

							<Image my={10} w="auto" onClick={() => console.log(globalState)} src={themeConfig.logo} />
							<Select w='auto' mx={2} placeholder="Select Company" size='sm'
								value={globalState?.currentCompany?.id} onChange={(e) => handleChangeCompany(e.target.value)}
							>
								{globalState?.companies?.map((x, i) =>
									<option key={i} value={x.id}>{x.name}</option>
								)}
							</Select>
							<Select w='auto' mx={2} placeholder="Select Project" size='sm'
								value={globalState?.currentProject?.id} onChange={(e) => handleChangeProject(e.target.value)}
							>{globalState?.projects?.map((x, i) =>
								<option key={i} value={x.id}>{x.name}</option>
							)}
							</Select>

							<Stack spacing="1" mx={1}>
								<NavButton
									label="Dashboard"
									icon={FcMoneyTransfer}
									onClick={() => navigate('/')}
									backgroundColor={isShowingDashboard()
										?
										'blackAlpha.300'
										:
										""}
								/>
								<NavButton
									label="Themes"
									icon={FcCloseUpMode}
									onClick={() => navigate('/themes')}
									backgroundColor={window.location.href.includes('themes')
										?
										'blackAlpha.300'
										:
										""}
								/>
								<NavButton
									label="Courses"
									icon={FcReading}
									onClick={() => navigate('/courses')}
									backgroundColor={window.location.href.includes('courses')
										?
										'blackAlpha.300'
										:
										""}
								/>
								<NavButton
									label="Shipipng"
									icon={FcRules}
									onClick={() => navigate('/shipping')}
									backgroundColor={window.location.href.includes('shipping')
										?
										'blackAlpha.300'
										:
										""}
								/>
								{/* <NavButton
									label="News"
									icon={FcNews}
									onClick={() => navigate('/news')}
									backgroundColor={window.location.href.includes('news')
										?
										'blackAlpha.300'
										:
										""}
								/>
								<NavButton
									label="Qr"
									icon={FcPrint}
									onClick={() => navigate('/qr')}
									backgroundColor={window.location.href.includes('qr')
										?
										'blackAlpha.300'
										:
										""}
								/> */}
							</Stack>
						</Stack>
						<Stack spacing={{ base: "5", sm: "6" }}>
							<NavButton label="Settings" icon={FcSettings} onClick={() => navigate('/settings')} />
							<Stack spacing="1">
								{/* <NavButton label="Help" icon={FiHelpCircle} /> 
										 <NavButton label="Logout" icon={FiLogOut} /> */}
								<Button onClick={handleLogout}>Logout</Button>
							</Stack>


							{/* <Box bg="bg-subtle" px="4" py="5" borderRadius="lg">
										<Stack spacing="4">
											<Stack spacing="1">
												<Text fontSize="sm" fontWeight="medium">
													Almost there
												</Text>
												<Text fontSize="sm" color="muted">
													Fill in some more information about you and your person.
												</Text>
											</Stack>
											<Progress
												value={80}
												size="sm"
												aria-label="Profile Update Progress"
											/>
											<HStack spacing="3">
												<Button variant="link" size="sm">
													Dismiss
												</Button>
												<Button variant="link" size="sm" colorScheme="blue">
													Update profile
												</Button>
											</HStack>
										</Stack>
									</Box> */}
							<Box w="full" mx={2} pb={100}>
								<UserProfile
									mb={100}
									name={globalState.user?.name}
									image={globalState.user?.photoProfile || user?.photoURL}
									email={globalState.user?.email}
								/>
							</Box>


						</Stack>
					</Stack>
					{/* </Flex> */}
				</Flex>
				{/* </Box> */}
			</Box>
		);

	return <></>;
}

export default SidebarComponentV2;
