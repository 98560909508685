import { Box, Container, HStack, Heading, Icon, SimpleGrid, Square, Stack, Text } from "@chakra-ui/react"
import { FaMoneyBillWaveAlt } from "react-icons/fa"
import { FiArrowDownRight, FiArrowUpRight, FiBookOpen, FiEye, FiMoreVertical, FiUsers } from "react-icons/fi"

function OfferStats () {
    const stats = [
		{
			label: 'Purchases Last 30 Days',
			value: '23',
		},
		{
			label: 'Net Revenue Last 30 Days',
			value: '56.87%',
		},
		{
			label: 'Net Revenue All Time',
			value: '12.87%',
		},
	]

    const Stats = (props) => {
		const { label, value, icon, delta, ...boxProps } = props
		return (
			<Box
				bgColor={'white'}
				px={{
					base: '4',
					md: '6',
				}}
				py={{
					base: '5',
					md: '6',
				}}
				bg="bg-surface"
				borderRadius="lg"
				boxShadow="sm"

				{...boxProps}
			>
				<Stack
					spacing={{
						base: '5',
						md: '6',
					}}
				>
					<Stack direction="row" justify="space-between">
						<HStack spacing="4">
							<Text fontWeight="medium">{label}</Text>
						</HStack>
						<Icon as={FiMoreVertical} boxSize="5" color="muted" />
					</Stack>
					<Stack spacing="4">
						<Heading
							size={{
								base: 'sm',
								md: 'md',
							}}
						>
							{value}
						</Heading>
					</Stack>
				</Stack>
			</Box>
		)
	}

    return (
        <Container maxW='full'>
					<SimpleGrid
						columns={{
							base: 1,
							md: 3,
						}}
						gap={{
							base: '5',
							md: '6',
						}}
					>
						{stats.map((x, id) => (
							<>						
							<Stats key={id} 
							icon={x.icon}
							label={x.label}
							value={x.value}
							delta={x.delta}
							/>
							</>
						))}
					</SimpleGrid>
				</Container>
    )
}
export default OfferStats