import { Heading } from '@chakra-ui/react';
import React from 'react'
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';


const PrompResultFilePage = () => {
    const { state } = useLocation();
    console.log(state, "thi is the state ");
    return (
        <>
            <Heading>Result file page</Heading>
            {parse(state)}
        </>
    )
}

export default PrompResultFilePage