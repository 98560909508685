import {
    Badge,
    Button,
    HStack,
    IconButton,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Table,
    TableContainer,
    Tag,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useState, useEffect, useRef } from "react";
import { FiEdit2 } from "react-icons/fi";
import { useGlobalState } from "../../Hooks/Contexts";
import { formatFrice } from "../../Utils/numberUtil";

export const TransactionTable = (props) => {
    const data = props.data;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [details, setDetails] = useState({});


    const handleViewDetails = (data) => {
        setDetails(data);
        onOpen();
    };

    return (
        <>
            <Table {...props} variant="striped" bgcolor="white" shadow={"md"}>
                <Thead>
                    <Tr>
                        <Th>Created Date</Th>
                        <Th>Transaction</Th>
                        <Th>OrderId</Th>
                        <Th>Transaction Value</Th>
                        <Th>Payment Status</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {data?.map((x, index) => {

                        return (

                            <Tr key={index}>
                                <Td fontSize="2xs">
                                    <Text fontWeight={500}>
                                        {moment(x?.createdAt?.seconds * 1000).format(
                                            "MMMM Do YYYY"
                                        )}
                                    </Text>
                                    <Text fontWeight={500}>
                                        {moment(x?.createdAt?.seconds * 1000).format("h:mm:ss a")}
                                    </Text>
                                </Td>

                                <Td>
                                    <Stack>
                                        <Text fontSize={12}>Name : <strong>{x?.name || x?.firstName + x?.lastName || x?.email}</strong></Text>
                                        <Text fontSize={12}>Phone : <strong>{x?.phone || x?.mobilePhone}</strong></Text>
                                    </Stack>
                                </Td>

                                <Td>
                                    <Stack w={'300px'}>
                                        <Text fontSize={12}><strong>{x?.externalId}</strong></Text>
                                    </Stack>
                                </Td>

                                <Td>
                                    <HStack>
                                        <Text color="muted" fontSize={"sm"} textTransform='uppercase' fontWeight={500}>
                                            {x.paymentMethod}
                                        </Text>

                                    </HStack>
                                    <Text color={"green.500"} fontSize={"sm"} fontWeight={500}>
                                        Rp.
                                        {formatFrice(x?.expected_amount || x?.amount)}
                                    </Text>
                                </Td>

                                <Td>
                                    <Badge
                                        textAlign={'center'}
                                        p={1}
                                        borderRadius='md'
                                        colorScheme={x?.paymentStatus === "PENDING" ? "red" : x?.paymentStatus === "PAID" ? "green" : ""}
                                    >
                                        {x?.paymentStatus}
                                    </Badge>
                                </Td>



                                <Td>
                                    <HStack spacing="1">
                                        <IconButton
                                            icon={<FiEdit2 fontSize="1.25rem" />}
                                            variant="ghost"
                                            aria-label="Edit member"
                                            onClick={()=> handleViewDetails(x)}
                                        />
                                    </HStack>
                                </Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
            <Modal isOpen={isOpen} onClose={onClose} size="5xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Orders</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                        <TableContainer>
                            <Table variant='simple' size="sm">
                                <Thead>
                                    <Tr>
                                        <Th>ID</Th>
                                        <Th>Product Name</Th>
                                        <Th>Quantity</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {details?.orders?.map((x, i) => (
                                        <Tr key={i}>
                                            <Td>{x?.id}</Td>
                                            <Td>{x?.name}</Td>
                                            <Td>{x?.qty}</Td>
                                        </Tr>
                                    ))}

                                </Tbody>
                            </Table>
                        </TableContainer>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
