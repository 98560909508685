import {
	Flex,
	Heading,
	Input,
	InputGroup,
	InputLeftElement,
	Select,
	Box,
	Button,
	ButtonGroup,
	Container,
	HStack,
	Icon,
	Stack,
	Text,
	useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import { TableComponent } from "../../Components/Tables/TableComponent";
import BreadCrumbComponent from "../../Components/BreadCrumbs/BreadCrumbComponent";
import { FiPlus, FiSearch } from "react-icons/fi";
import BasicCardComponent from "../../Components/Cards/BasicCardComponent";

const Pages = () => {
	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	});

	const data = [
		{ title: "Home", link: "/" },
		{ title: "Pages", link: "/pages" },
	];

	const pages = [
		{
			id: 1,
			image: "https://bit.ly/dan-abramov",
			title: "Pages A",
			section: 1,
			lesson: 1,
			time: 124124124,
			editLink: { pathname: "/pages/1", state: "Pages A" },
		},
		{
			id: 2,
			image: "https://bit.ly/dan-abramov",
			title: "Pages B",
			section: 1,
			lesson: 0,
			time: 124124124,
			editLink: { pathname: "/pages/2", state: "Pages B" },
		},
	];
	return (
		<>
			<BreadCrumbComponent data={data} />
			<Heading>Pages</Heading>
			<Container
				py={{
					base: "4",
					md: "8",
				}}
				px={{
					base: "0",
					md: 8,
				}}
				maxW="7xl"
			>
				<Box
					bg="bg-surface"
					boxShadow={{
						base: "none",
						md: "sm",
					}}
					borderRadius={{
						base: "none",
						md: "lg",
					}}
				>
					<Stack spacing="5">
						<Box>
							{pages?.map((item, i) => (
								<BasicCardComponent key={i} data={item} />
							))}
						</Box>
						<Box
							px={{
								base: "4",
								md: "6",
							}}
							pb="5"
						>
							<HStack spacing="3" justify="space-between">
								{!isMobile && (
									<Text color="muted" fontSize="sm">
										Showing 1 to 5 of 42 results
									</Text>
								)}
								<ButtonGroup
									spacing="3"
									justifyContent="space-between"
									width={{
										base: "full",
										md: "auto",
									}}
									variant="secondary"
								>
									<Button>Previous</Button>
									<Button>Next</Button>
								</ButtonGroup>
							</HStack>
						</Box>
					</Stack>
				</Box>
			</Container>
		</>
	);
};

export default Pages;
